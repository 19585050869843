import { authRole, AuthType } from "./AppEnums";
import IntlMessages from "@cafefinz/utility/IntlMessages";

export const defaultUser = {
  displayName: "John Alex",
  email: "demo@example.com",
  authType: AuthType.JWT_AUTH,
  token: "access-token",
  role: authRole.admin,
  photoURL: "/assets/images/avatar/A11.jpg",
};
export const initialUrl = "/dashboards"; // this url will open after login

export const optionTypeIsPinned = [
  {
    value: "true",
    text: <IntlMessages id="Pinned" />,
  },
  {
    value: "false",
    text: <IntlMessages id="Unpinned" />,
  },
];

export const PageDefault = 1;
export const TakeDefault = 10;
export const MaxTakeDefault = 1000;
export const PrefixLinkYoutube = "youtu";