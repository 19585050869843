import { useCallback } from "react";
import useSWRMutation from "swr/mutation"
import { URL_API } from "@cafefinz/services/endpoint";
import { updateRole } from "@cafefinz/services/apis/roles";

const useUpdateTag = () => {
  const updateData = useCallback((_url, { arg }) => {
    const { id, formData } = arg;
    const formattedPermissionRoles = formData.permissionRoles?.map(value => ({
      permissionId: value,
    }))
    updateRole({
      id,
      formData: {
        ...formData,
        permissionRoles: formattedPermissionRoles,
      }
    })
  }, [updateRole])
  
  return useSWRMutation(URL_API.UPDATE_ROLE, updateData);
};

export default useUpdateTag;