import { getUserByRoleId } from "@cafefinz/services/apis/roles";
import { URL_API } from "@cafefinz/services/endpoint";
import { convertToS3Link } from "@cafefinz/utility/Utils";
import { useMemo } from "react";
import { S3Type } from "shared/constants/AppEnums";
import useSWRMutation from "swr/mutation";

const useGetUserByRoleId = () => {
  const { trigger, data, isMutating } = useSWRMutation(
    URL_API.GET_USER_BY_ROLE_ID,
    (_url, { arg }) => getUserByRoleId(arg)
  );

  const formattedData = useMemo(() => {
    const currentTime = new Date().getTime();
    return data?.data?.map((item) => {
      const { s3ProfilePictureFileName, id, wallImg } = item ?? {};
      return {
        ...item,
        avatarUser: `${convertToS3Link(
          S3Type.UID_AVATAR,
          id,
          s3ProfilePictureFileName
        )}?${currentTime}`,
        wallpaperUser: `${convertToS3Link(
          S3Type.UID_WALL,
          id,
          wallImg
        )}?${currentTime}`,
      };
    });
  }, [data]);
  return {
    triggerGetUserByRoleId: trigger,
    dataGetUserByRoleId: formattedData ?? [],
    isMutatingGetUserByRoleId: isMutating,
    totalCountGetUserByRoleId: data?.meta?.totalCount ?? 0,
  };
};
export default useGetUserByRoleId;
