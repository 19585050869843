import { Image as AntImage } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ImageDefault from "../../assets/images/ImageDefault.png";
import "./index.style.less";

const LazyImage = ({ src, alt, className }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div>
      <AntImage
        style={{
          filter: `${!loaded ? "blur(20px)" : ""}`,
          transition: "0.5s filter linear",
        }}
        src={src}
        alt={alt}
        className={className}
        onLoad={() => setLoaded(true)}
        onError={() => setLoaded(false)}
        fallback={ImageDefault}
      />
    </div>
  );
};

export default React.memo(LazyImage);
LazyImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
};
