import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Setting";
import Dashboard from "./Dashboard";
import Common from "./Common";
import User from "./User";
import Tag from "./Tags";
import Banners from "./Banners";

const reducers = (history) =>
	combineReducers({
		router: connectRouter(history),
		settings: Settings,
		dashboard: Dashboard,
		common: Common,
		user: User,
		tag: Tag,
		banners: Banners,
	});
export default reducers;
