export const URL_API = {
  GET_LIST_TAGS: "cms-tag/get-all-tags",
  POST_LIST_TAG: "cms-tag",
  GET_DETAIL_TAG: "cms-tag/id",
  UPDATE_LIST_TAG: "cms-tag",
  DELETE_LIST_TAG: "cms-tag/delete-tags",
  ASSIGN_ONE_TAG: "cms-tag/assign-single-tag-to-groups",
  ASSIGN_LIST_TAG: "cms-tag/assign-tags-to-groups",
  GET_ALL_TAGS_NO_PAGINATION: "cms-tag/get-all-tags-no-pagination",
  GET_THREADS_BY_TAG_ID: "cms-tag/get-threads-by-tag-id",
  GET_THREADS_BY_GROUP_ID: "cms-thread/get-threads-by-groupId",
  // GET IMAGE LINK S3
  GET_IMAGE_LINK_S3: "cms-thread/get-link-s3",
  DETELE_IMAGE_LINK_S3: "cms-thread/delete-s3",
  MOVE_FILE_IMAGE_S3: "cms-thread/move-file-s3",
  // group
  GET_LIST_GROUPS: "cms-group/get-all-groups",
  GET_GROUP_BY_ID: "cms-group/id",
  CREATE_GROUP: "cms-group/create-groups",
  UPDATE_GROUP: "cms-group",
  DELETE_GROUP: "cms-group/delete-groups",
  GET_GROUP_PRESIGNED_AVATAR_URL: "cms-group/upload-avatar",
  GET_GROUP_PRESIGNED_WALLPAPER_URL: "cms-group/upload-wallpaper",
  GET_GROUP_BY_CATEGORY_ID: "cms-group/get-groups-by-category-id",
  GET_GROUP_BY_TAG_ID: "cms-group/get-groups-by-tag-id",
  GET_GROUP_JOINED_BY_USER_ID: "cms-group/get-groups-user-joined",
  GET_GROUP_NO_PAGINAION: "cms-group/get-all-groups-no-pagination",
  GET_GROUP_BY_USER_CREATE: "cms-group/get-groups-by-user-create",
  GET_USER_BY_GROUP_ID: "cms-group/get-users-by-groupId",

  //user
  GET_LIST_USERS: "cms-user/get-list-user",
  GET_USER_TO_VIEW: "cms-user/id",
  POST_SEND_MAILS: "cms-user/send-mails",
  POST_CREATE_USER: "cms-user/create-account",
  POST_BLOCK_USER: "cms-user/block-account",
  POST_UNBLOCK_USER: "cms-user/un-block-account",
  PATCH_EDIT_USER: "cms-user",
  DELETE_LIST_USERS: "cms-user/delete-users",
  GET_LIST_NATIONS: "cms-user/get-all-nations",
  GET_GROUP_BY_USER_ID: "cms-user/get-groups-by-userId",

  //banner
  UPLOAD_AVATAR_BANNER: "banner/upload-avatar",
  GET_LIST_BANNERS: "banner/get-all-banner",
  GET_BANNER_TO_VIEW: "banner/id",
  DELETE_LIST_BANNERS: "banner",
  POST_ACTIVE_BANNERS: "banner/active-multiple-banner",
  POST_DE_ACTIVE_BANNERS: "banner/deactive-multiple-banner",
  POST_CREATE_BANNER: "banner/create-banner",
  PATCH_EDIT_BANNER: "banner",
  GET_THREADS_BY_USER_ID: "cms-thread/get-threads-by-userId",

  //email statistic
  GET_EMAIL_STATISTIC: "email-statistic",
  DELETE_EMAIL_STATISTIC: "email-statistic",
  GET_EMAIL_STATISTIC_TO_VIEW: "email-statistic/id",
  GET_EMAIL_STATISTIC_STATISTIC: "email-statistic/statistic",

  //email campaign
  GET_LIST_EMAIL_CAMPAIGNS: "/email-campaign",
  CREATE_EMAIL_CAMPAIGN: "/email-campaign",
  GET_EMAIL_CAMPAIGN_TO_VIEW: "email-campaign",
  DELETE_LIST_EMAIL_CAMPAIGNS: "email-campaign",
  POST_ACTIVE_EMAIL_CAMPAIGNS: "email-campaign/active-multiple-emailCampaigns",
  POST_DE_ACTIVE_EMAIL_CAMPAIGNS:
    "email-campaign/deactive-multiple-emailCampaigns",
  PATCH_EDIT_EMAIL_CAMPAIGNS: "/email-campaign",

  //role:
  POST_ROLE: "role",
  GET_LIST_ROLES: "role/get-all-roles",
  UPDATE_ROLE: "role",
  DELETE_ROLE: "role/delete-roles",
  GET_ROLE_FOR_EDIT: "role/id",
  GET_ALL_PERMISSIONS: "role/get-all-permissions",
  GET_USER_BY_ROLE_ID: "role/get-users-by-roleId",

  //category
  GET_LIST_CATEGORY: "cms-category/get-all-category",
  GET_CATEGORY_BY_ID: "cms-category/id",
  CREATE_CATEGORY: "cms-category",
  UPDATE_CATEGORY: "cms-category",
  DELETE_CATEGORY: "cms-category/delete-categories",
  ASSIGN_CATEGORY: "cms-category/assign-categories-to-groups",
  GET_ALL_CATEGORIES_NO_PAGINATION:
    "cms-category/get-all-categories-no-pagination",
  GET_USER_BY_CATEGORY_ID: "cms-category/get-users-by-categoryId",

  //setting
  UPDATE_SETTING: "cms-setting/update-infomation",
  GET_SETTING: "cms-setting/get-settings",
  GET_S3_LOGO: "cms-setting/upload-logo",
  GET_S3_FAVICON: "cms-setting/upload-favicon",
  GET_ALL_WEB_RULE: "cms-setting/get-all-web-rule",
  ADD_RULE: "cms-setting/add-rule",
  PUT_S3_IMAGE_SETTING: "PUT_S3_IMAGE_SETTING",

  //profile
  UPDATE_PROFILE: "users/update-user-profile",
  UPDATE_PROFILE_AVATAR: "users/presigned-url",
  UPDATE_PROFILE_PASSWORD: "users/update-password",

  // post
  GET_LIST_POST: "cms-thread/get-all-threads",
  GET_POST_BY_ID: "cms-thread/id",
  CREATE_POST: "cms-thread/create-thread",
  DELETE_POST: "cms-thread/delete-multi-threads",
  UPDATE_POST: "cms-thread",
  GET_INTERNAL_USERS: "cms-thread/get-internal-users",
  GET_TAGS_IN_GROUP: "cms-thread/get-tags-in-group",
  CREATE_COMMENT: "cms-thread/create-comment",

  // poll
  DETAIL_STATISTICAL_POLL: "cms-thread/detail-statistical-poll",
  DETAIL_POLL_OPTION: "cms-thread/detail-poll-option",

  // post template
  GET_LIST_POST_TEMPLATE: "cms-template/get-all-templates",
  GET_LIST_POST_TEMPLATE_ACTIVE: "cms-template/get-all-templates-active",
  GET_POST_TEMPLATE_BY_ID: "cms-template/id",
  CREATE_POST_TEMPLATE: "cms-template/create-template",
  DELETE_POST_TEMPLATE: "cms-template/delete-multi-templates",
  UPDATE_POST_TEMPLATE: "cms-template",

  //report
  GET_LIST_USER: "users",
  GET_LIST_REPORT: "cms-report/get-all-reports",
  GET_REPORT_BY_ID: "cms-report/id",
  POST_APPROVED_REPORT: "cms-report/allow-report",
  POST_DELETE_REPORT: "cms-report/deleted-report",

  //groupRule
  GET_ALL_GROUP_RULE: "cms-group/get-all-rules-by-groupId",
  CREATE_GROUP_RULE: "cms-group/create-group-rule",
  UPDATE_GROUP_RULE: "cms-group/update-group-rule",
  DELETE_GROUP_RULE: "cms-group/delete-group-rule",
  GET_GROUP_RULE_BY_ID: "cms-group/get-rule-by-id/id",
  ADD_USER_IN_ROLE: "cms-group/add-users-in-role",

  //comment
  COMMENTS: "comments",
  GET_COMMENTS_BY_THREAD: "comments/get-by-thread",
  GET_COMMENTS_BY_PARENT_COMMENT: "comments/get-by-parent-comment",

  // statistic
  GET_STATISTIC: "cms-statistic",
};
