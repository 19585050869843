import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Tree } from "antd";
import useGetAllPermissions from "hooks/apis/roles/useGetAllPermissions";
import IntlMessages from "@cafefinz/utility/IntlMessages";

const PermissionTreeFormItem = ({ onChange, value, isDisabled }) => {
  const [checkedKeys, setCheckedKeys] = useState([]);
  useEffect(() => setCheckedKeys(value ?? []), [value]);

  const { data: permissions } = useGetAllPermissions();
  console.log(permissions);
  const permissionsTreeData = useMemo(() => {
    const permissionTypes = [
      ...new Set(permissions.map((permission) => permission?.type)),
    ];
    const permissionsByType = permissionTypes.map((type) => {
      const typePermissions = permissions
        .filter((permission) => permission?.type === type)
        .map((permission) => ({
          title: <IntlMessages id={`${permission?.name}`} />,
          key: permission?.id,
        }));
      return {
        title: <IntlMessages id={`${type}`} />,
        key: type,
        children: typePermissions,
      };
    });
    return [
      {
        title: <IntlMessages id="app.roles.pages" />,
        key: "Pages",
        children: permissionsByType,
      },
    ];
  }, [permissions]);
  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
    if (typeof onChange === "function") {
      const checkedPermissions = checkedKeysValue
        .filter((value) => !isNaN(Number(value)))
      onChange(checkedPermissions);
    }
  };
  return (
    <Tree
      checkable
      disabled={isDisabled}
      defaultExpandAll
      onCheck={onCheck}
      checkedKeys={checkedKeys}
      treeData={permissionsTreeData}
      selectable={false}
    />
  );
};

PermissionTreeFormItem.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  isDisabled: PropTypes.bool,
};
export default PermissionTreeFormItem;
