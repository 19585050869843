// @ts-nocheck
import React from "react";
import { UserPermission } from "shared/constants/AppEnums";
import Roles from "./Roles";

const Dashboard = React.lazy(() => import("./Dashboard"));
const Users = React.lazy(() => import("./Users"));
const Groups = React.lazy(() => import("./Groups"));
const Report = React.lazy(() => import("./Report"));
const Settings = React.lazy(() => import("./Settings"));
const Categories = React.lazy(() => import("./Categories"));
const Tags = React.lazy(() => import("./Tags"));
const Banners = React.lazy(() => import("./Banners"));
const Emails = React.lazy(() => import("./Emails"));
const EmailAnalysis = React.lazy(() => import("./EmailAnalysis"));
const Posts = React.lazy(() => import("./Posts"));
const PostTemplates = React.lazy(() => import("./PostTemplates"));

export const dashboardConfig = [
  {
    path: "/dashboards",
    element: <Dashboard />,
  },
  {
    path: "/dashboards/email-analysis",
    element: <EmailAnalysis />,
    scopes: [],
  },
  {
    path: "/dashboards/emails",
    element: <Emails />,
    scopes: [
      UserPermission.EMAIL_CREATE,
      UserPermission.EMAIL_DELETE,
      UserPermission.EMAIL_EDIT,
    ],
  },
  {
    path: "/dashboards/banners",
    element: <Banners />,
    scopes: [
      UserPermission.BANNER_CREATE,
      UserPermission.BANNER_EDIT,
      UserPermission.BANNER_DELETE,
    ],
  },
  {
    path: "/dashboards/categories",
    element: <Categories />,
    scopes: [
      UserPermission.CATEGORY_CREATE,
      UserPermission.CATEGORY_EDIT,
      UserPermission.CATEGORY_DELETE,
    ],
  },
  {
    path: "/dashboards/tags",
    element: <Tags />,
    scopes: [
      UserPermission.TAGS_CREATE,
      UserPermission.TAGS_EDIT,
      UserPermission.TAGS_DELETE,
    ],
  },
  {
    path: "/dashboards/groups",
    element: <Groups />,
    scopes: [
      UserPermission.GROUPS_CREATE,
      UserPermission.GROUPS_EDIT,
      UserPermission.GROUPS_DELETE,
    ],
  },
  {
    path: "/dashboards/report",
    element: <Report />,
    scopes: [UserPermission.REPORT_ACTION, UserPermission.REPORT_DELETE],
  },
  {
    path: "/dashboards/users",
    element: <Users />,
    scopes: [
      UserPermission.USERS_CREATE,
      UserPermission.USERS_EDIT,
      UserPermission.USERS_DELETE,
    ],
  },
  {
    path: "/dashboards/roles",
    element: <Roles />,
    scopes: [
      UserPermission.ROLES_CREATE,
      UserPermission.ROLES_EDIT,
      UserPermission.ROLES_DELETE,
    ],
  },
  {
    path: "/dashboards/settings",
    element: <Settings />,
    scopes: [
      UserPermission.SETTINGS_CREATE_RULE,
      UserPermission.SETTINGS_DELETE_RULE,
      UserPermission.SETTINGS_EDIT_INFORMATION,
      UserPermission.SETTINGS_EDIT_RULE,
    ],
  },
  {
    path: "/dashboards/posts",
    element: <Posts />,
    scopes: [
      UserPermission.POSTS_CREATE,
      UserPermission.POSTS_EDIT,
      UserPermission.POSTS_DELETE,
    ],
  },
  {
    path: "/dashboards/post-templates",
    element: <PostTemplates />,
    scopes: [
      UserPermission.POSTS_CREATE,
      UserPermission.POSTS_EDIT,
      UserPermission.POSTS_DELETE,
    ],
  },
];
