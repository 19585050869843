import React, { useMemo } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Button } from "antd";
import PropTypes from "prop-types";
import { filterPermissionGrantedList } from "@cafefinz/utility/Utils";
import { useAuthUser } from "@cafefinz/utility/AuthHooks";

const AppColumnActionOption = ({ items }) => {
  const { user } = useAuthUser();
	const menuItems = useMemo(() => {
    const filteredGrantedItems = filterPermissionGrantedList({listItems: items, permissions: user?.permissions});
    return filteredGrantedItems.map((item, index) => ({
      key: index,
      label: (
        <p
          style={{ fontSize: 14, minWidth: "100px", margin: "0" }}
          onClick={item.onClick}
        >
          {item.label}
        </p>
      ),
      disabled: item?.isDisabled,
    }));
  }, [items, user]);
  return menuItems.length > 0 ? (
    <Dropdown
      menu={{ items: menuItems }}
      trigger={["click"]}
      placement="bottomRight"
    >
      <Button
        type="circle"
        style={{
          border: "1px solid transparent",
          borderColor: "rgba(0, 0, 0, 0.12)",
        }}
      >
        <MoreOutlined />
      </Button>
    </Dropdown>
  ) : null;
};

export default AppColumnActionOption;

AppColumnActionOption.propTypes = {
  items: PropTypes.array,
};

AppColumnActionOption.defaultProps = {
  items: [],
};
