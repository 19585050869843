import AppsContent from "@cafefinz/core/AppsContainer/AppsContent";
import AppTableContainer from "@cafefinz/core/AppTableContainer";
import { Drawer } from "antd";
import { useCallback, useMemo, useState } from "react";
import "./index.style.less";
import { PageDefault } from "shared/constants/AppConst";

const useAppShowListDrawer = ({
  title,
  data,
  totalCount,
  loading,
  columns,
  changeParamDrawer,
  // currentPage,
  // searchValueDrawer,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [page, setPage] = useState(PageDefault);
  const openShowListDrawer = useCallback(() => {
    setIsVisible(true);
  }, []);
  const closeListDrawer = useCallback(() => {
    setIsVisible(false);
    setPage(PageDefault);
  }, []);

  const contextShowListDrawer = useMemo(() => {
    return (
      <Drawer
        title={title}
        placement="right"
        open={isVisible}
        onClose={closeListDrawer}
        width="60%"
      >
        {/* <div className="input-search">
          <Input
            id="user-name"
            width="100px"
            placeholder={messages["common.searchHere"]}
            type="search"
            onChange={(e) => searchValueDrawer(e.target.value)}
          />
        </div> */}
        <AppsContent>
          <AppTableContainer
            data={data}
            loading={loading}
            columns={columns}
            pagination={{
              onChange: (currentPage, take) => {
                changeParamDrawer(currentPage, take);
                setPage(currentPage);
              },
              total: totalCount,
              current: page,
            }}
          />
        </AppsContent>
      </Drawer>
    );
  }, [isVisible, title, data, loading, page]);
  return {
    openShowListDrawer,
    contextShowListDrawer,
  };
};
export default useAppShowListDrawer;
