import { authRole } from "../../../shared/constants/AppEnums";

export const getUserFromAuth0 = (user) => {
	if (user)
		return {
			id: 1,
			uid: user.sub,
			displayName: user.name,
			email: user.email,
			photoURL: user.picture,
			role: authRole.user,
		};
	return user;
};

export const getUserFromFirebase = (user) => {
	if (user)
		return {
			id: 1,
			uid: user.uid,
			displayName: user.displayName ? user.displayName : "Sky User",
			email: user.email,
			photoURL: user.photoURL ? user.photoURL : "/assets/images/avatar/A11.jpg",
			role: authRole.user,
		};
	return user;
};
export const getUserFromAWS = (user) => {
	if (user)
		return {
			id: 1,
			uid: user.username,
			displayName: user.attributes.name ? user.attributes.name : "Sky User",
			email: user.attributes.email,
			photoURL: user.photoURL,
			role: authRole.user,
		};
	return user;
};

export const getUserFromJwtAuth = (user) => {
	//console.log("getUserFromJwtAuth", user);
	// if (user)
	// 	return {
	// 		id: 1,
	// 		uid: user.id,
	// 		displayName: user.uniqueUrl,
	// 		email: user.email,
	// 		photoURL: `https://s3.ap-southeast-1.amazonaws.com/skydev-cafefinz.dev/uid_${user.id}/${user.s3ProfilePictureFileName}`,
	// 		role: authRole?.user,
	// 	};
	const permissions = user?.roles?.permissionRoles?.filter(permissionRole => !permissionRole.deletedAt).map(permissionRole => permissionRole?.permission?.name)
	return {
		...user,
		permissions,
	};
};
