import {
	GET_LIST_TAGS,
	//DELETE_LIST_TAG,
} from "../../shared/constants/ActionTypes";

const initialState = {
	tagsData: null,
};

const TagReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_LIST_TAGS:
			return {
				...state,
				tagsData: action.payload,
			};
		//case DELETE_LIST_TAG: {
		//	return {
		//		...state,
		//		tagsData: [
		//			state?.tagsData?.data?.filter((tag) => tag?.id !== action?.idTag),
		//		],
		//	};
		//}
		default:
			return state;
	}
};
export default TagReducer;
