import { Input, message } from "antd";
import { useState, useMemo, useEffect, useCallback } from "react";

import AppButtonActions from "@cafefinz/component/AppButtonActions";
import AppPageMetadata from "@cafefinz/core/AppPageMetadata";
import AppsContent from "@cafefinz/core/AppsContainer/AppsContent";
import AppsHeader from "@cafefinz/core/AppsContainer/AppsHeader";
import AppsContainer from "@cafefinz/core/AppsContainer";
import AppTableContainer from "@cafefinz/core/AppTableContainer";
import "./index.style.less";
import useAppCreateOrEditModal from "hooks/UseAppCreateOrEditModal";
import {
  getRoleColumns,
  createOrEditRoleFieldConfig,
  userBelongRoleColumns,
} from "./RolesConfig";
import useGetListRoles from "hooks/apis/roles/useGetListRoles";
import useCreateRole from "hooks/apis/roles/useCreateRole";
import { useIntl } from "react-intl";
import IntlMessages from "@cafefinz/utility/IntlMessages";
import useDebounce from "hooks/useDebounce";
import { UserPermission } from "shared/constants/AppEnums";
import useGetInfinityUsers from "hooks/apis/users/useGetInfinityUsers";
import { PageDefault, TakeDefault } from "shared/constants/AppConst";
import useGetUserByRoleId from "hooks/apis/roles/useGetUserByRoleId";
import useAppShowListDrawer from "hooks/useAppShowListDrawer";

const Roles = () => {
  const { messages } = useIntl();
  const [selectedItem, setSelectedItem] = useState({});
  const [params, setParams] = useState({
    page: PageDefault,
    take: TakeDefault,
    name: "",
  });
  const [paramSelect, setParamSelect] = useState({
    user: "",
  });
  const { listUsers, fetchMoreUsers, isReachingEndUsers, isLoadingUsers } =
    useGetInfinityUsers(paramSelect);
  const { trigger: triggerCreateRole, isMutating: isMutatingCreateRole } =
    useCreateRole();
  const { data: rolesData, mutate: mutateRolesData } = useGetListRoles(params);
  const { mutate: mutateRolesDataNew } = useGetListRoles({
    page: PageDefault,
    take: TakeDefault,
    name: "",
  });
  const {
    dataGetUserByRoleId,
    isMutatingGetUserByRoleId,
    totalCountGetUserByRoleId,
    triggerGetUserByRoleId,
  } = useGetUserByRoleId();

  // drawer show list user belong role
  const { contextShowListDrawer, openShowListDrawer } = useAppShowListDrawer({
    title: <IntlMessages id="app.roles.numOfUser" />,
    data: dataGetUserByRoleId,
    columns: userBelongRoleColumns(),
    loading: isMutatingGetUserByRoleId,
    totalCount: totalCountGetUserByRoleId,
    changeParamDrawer: (currentPage, take) =>
      triggerGetUserByRoleId({
        page: currentPage,
        take,
        roleId: selectedItem?.id,
      }),
  });

  const [searchValue, setSearchValue] = useState("");
  const { debouncedValue } = useDebounce(searchValue);
  useEffect(() => {
    setParams((prev) => {
      return {
        ...prev,
        name: debouncedValue,
        page: PageDefault,
      };
    });
  }, [debouncedValue]);
  const onHandleCreateRole = async (formData) => {
    triggerCreateRole(
      {
        ...formData,
      },
      {
        onSuccess: () => {
          mutateRolesDataNew();
          message.success(messages["app.roles.createRole.success"]);
          closeModal();
        },
        onError: (error) => {
          message.error(
            error?.response?.data?.message ||
              messages["app.roles.createRole.error"]
          );
        },
      }
    );
  };
  const {
    contextHolder,
    openModal: openCreateOrEditModal,
    closeModal,
  } = useAppCreateOrEditModal({
    fieldsConfig: createOrEditRoleFieldConfig({
      listUsers,
      fetchMoreUsers,
      isReachingEndUsers,
      loadingUsers: isLoadingUsers,
      searchOptionUsers: (value) =>
        setParamSelect((prev) => ({ ...prev, user: value })),
    }),
    onFinish: onHandleCreateRole,
    title: <IntlMessages id="app.roles.createRole" />,
    data: { userIds: [] },
    loading: isMutatingCreateRole,
  });

  const handleShowsUserBelongRole = useCallback((value) => {
    setSelectedItem(value);
    openShowListDrawer();
    triggerGetUserByRoleId({
      page: PageDefault,
      take: TakeDefault,
      roleId: value?.id,
    });
  }, []);

  const roleColumns = useMemo(
    () =>
      getRoleColumns({
        updateListRoles: mutateRolesData,
        handleShowsUserBelongRole,
        page: params.page,
      }),
    [getRoleColumns, handleShowsUserBelongRole]
  );

  return (
    <>
      <AppPageMetadata title={messages["sidebar.app.roles"]} />
      <AppsContainer
        title={messages["sidebar.app.roles"]}
        type="bottom"
        fullView
      >
        <AppsHeader>
          <div className="role-header">
            <div className="role-header-input-view">
              <Input
                id="role-name"
                placeholder={messages["common.searchHere"]}
                type="search"
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div className="role-header-right">
              <AppButtonActions
                scopes={[UserPermission.ROLES_CREATE]}
                title={<IntlMessages id="app.roles.createRole" />}
                type="primary"
                onClick={() => openCreateOrEditModal()}
              />
            </div>
          </div>
        </AppsHeader>
        <AppsContent
          style={{
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <AppTableContainer
            sticky
            data={rolesData?.data}
            columns={roleColumns}
            scroll={{ x: "auto" }}
            pagination={{
              current: params.page,
              onChange: (page, pageSize) => {
                setParams((prev) => {
                  return {
                    ...prev,
                    page: page,
                    take: pageSize,
                  };
                });
              },
              total: rolesData?.meta.totalCount,
              showTotal: (total, range) => (
                <p>
                  {range[0]}-{range[1]} trên {total} records
                </p>
              ),
            }}
          />
        </AppsContent>
        {contextHolder}
        {contextShowListDrawer}
      </AppsContainer>
    </>
  );
};
export default Roles;
