import jwtAxios from "@cafefinz/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@cafefinz/services/endpoint";

const getListBanners = async (params) => {
	const { data } = await jwtAxios.get(URL_API.GET_LIST_BANNERS, { params });
	return data;
}

export { getListBanners }


