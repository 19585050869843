import React from "react"
import PropTypes from "prop-types";
import ButtonPermission from "@cafefinz/core/AppPermission/ButtonPermission";

const AppButtonActions = (/** @type {{ onClick: () => void; disabled: boolean, title: string }} */ props) => {
    return (
        <ButtonPermission
            scopes={props?.scopes}
            type="primary"
            disabled={props?.disabled}
            style={props.style}
            onClick={props?.onClick}>
            {props.title}
        </ButtonPermission>
    )
}

AppButtonActions.propTypes = {
    scopes: PropTypes.array,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object
}

AppButtonActions.defaultProps = {
    title: "CafeFinzButton",
    disabled: false,
    onClick: () => { },
    style: {}
};

export default React.memo(AppButtonActions)