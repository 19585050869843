import IntlMessages from "@cafefinz/utility/IntlMessages";
import {
  Button,
  Checkbox,
  // Checkbox,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Menu,
  Spin,
} from "antd";
import Search from "antd/lib/transfer/search";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import useIntl from "react-intl/lib/src/components/useIntl";
import { AppSearchColumn } from "shared/constants/AppEnums";

export const EmptyValue = "empty";

const useColumnSearch = () => {
  const [form] = Form.useForm();
  const { messages } = useIntl();

  const itemRender = useCallback(
    ({
      handleSelectedKeys,
      type,
      selectedKeys,
      placeholder,
      isReachingEnd,
      fetchMoreData,
      listData,
      searchOptionListItem,
      isLoadingListItem,
      isEmptyValue = false,
    }) => {
      const refFrom = useRef(null);
      const refTo = useRef(null);
      const [inViewRef, inView] = useInView();

      useEffect(() => {
        if (inView && !isReachingEnd) {
          fetchMoreData?.();
        }
      }, [inView, isReachingEnd, fetchMoreData]);

      const listMenuItemRender = useMemo(() => {
        const listDataCopy = listData ? [...listData] : [];
        if (
          isEmptyValue &&
          listData &&
          listData?.length &&
          !(listDataCopy?.[0]?.id === EmptyValue)
        ) {
          listDataCopy.unshift({
            id: EmptyValue,
            name: <IntlMessages id="app.empty" />,
          });
        }
        if (!listDataCopy?.length && !isLoadingListItem) return <Empty />;
        return listDataCopy?.map((item, index) => {
          const ref = index === listDataCopy.length - 1 ? inViewRef : null;
          return (
            <>
              <Menu.Item key={item?.id}>
                <Checkbox
                  checked={
                    !!(selectedKeys ?? []).filter(
                      (element) => element === item?.id.toString()
                    ).length
                  }
                />
                <span ref={ref}>{item?.name}</span>
              </Menu.Item>
            </>
          );
        });
      }, [listData, inViewRef, isLoadingListItem, selectedKeys]);

      switch (type) {
        case AppSearchColumn.DATETIME:
          return (
            <Form.Item noStyle name={AppSearchColumn.DATETIME}>
              <DatePicker.RangePicker
                format="DD-MM-YYYY"
                onChange={(value) => handleSelectedKeys(value)}
                value={selectedKeys}
              />
            </Form.Item>
          );
        case AppSearchColumn.INPUT_RANGE:
          return (
            <Form.Item noStyle name={AppSearchColumn.INPUT_RANGE}>
              <Input.Group compact>
                <InputNumber
                  min={1}
                  placeholder={messages["app.from"]}
                  ref={refFrom}
                  max={refTo?.current?.value}
                  value={selectedKeys?.[0]?.from}
                  onChange={(value) =>
                    handleSelectedKeys({
                      from: value?.toString(),
                      to: refTo?.current?.value,
                    })
                  }
                />
                <InputNumber
                  min={refFrom?.current?.value}
                  placeholder={messages["app.to"]}
                  ref={refTo}
                  value={selectedKeys?.[0]?.to}
                  onChange={(value) =>
                    handleSelectedKeys({
                      from: refFrom?.current?.value,
                      to: value?.toString(),
                    })
                  }
                />
              </Input.Group>
            </Form.Item>
          );
        case AppSearchColumn.LIST_ITEM:
          return (
            <Form.Item noStyle name={AppSearchColumn.LIST_ITEM}>
              <Search onChange={(e) => searchOptionListItem(e.target.value)} />
              <Menu
                multiple={true}
                value={selectedKeys}
                onSelect={handleSelectedKeys}
                onDeselect={handleSelectedKeys}
                selectedKeys={selectedKeys}
              >
                {listMenuItemRender}
                {isLoadingListItem && (
                  <Spin style={{ display: "flex", justifyContent: "center" }} />
                )}
              </Menu>
            </Form.Item>
          );
        case AppSearchColumn.INPUT_NUMBER:
          return (
            <Form.Item noStyle name={AppSearchColumn.INPUT}>
              <InputNumber
                min={0}
                onChange={handleSelectedKeys}
                placeholder={placeholder}
                value={selectedKeys?.[0]}
              />
            </Form.Item>
          );
        default:
          return null;
      }
    },
    []
  );
  const getColumnSearchProps = ({
    type,
    placeholder,
    isReachingEnd,
    fetchMoreData,
    listData,
    searchOptionListItem,
    isLoadingListItem,
    isEmptyValue,
  }) => {
    const [hasFirstClick, setHasFirstClick] = useState(false);
    const handleFirstClick = (event) => {
      if (event && !hasFirstClick) {
        fetchMoreData?.();
        setHasFirstClick(true);
      }
    };

    return {
      filterDropdown: ({ confirm, setSelectedKeys, selectedKeys }) => {
        const handleSelectedKeys = (e) => {
          switch (type) {
            case AppSearchColumn.DATETIME:
              setSelectedKeys(e?.length ? e : []);
              break;
            case AppSearchColumn.LIST_ITEM:
              setSelectedKeys(e.selectedKeys);
              break;
            case AppSearchColumn.INPUT_RANGE:
            default:
              setSelectedKeys(e ? [e] : []);
              break;
          }
        };
        return (
          <div onKeyDown={(e) => e.stopPropagation()}>
            <Form form={form} onFinish={() => confirm()}>
              <div style={{ padding: 8 }}>
                {itemRender({
                  handleSelectedKeys,
                  type,
                  selectedKeys,
                  placeholder,
                  fetchMoreData,
                  isReachingEnd,
                  listData,
                  searchOptionListItem,
                  isLoadingListItem,
                  isEmptyValue,
                })}
              </div>
              <div className="ant-table-filter-dropdown-btns">
                <Button
                  type="button"
                  className="ant-btn ant-btn-link ant-btn-sm"
                  disabled={!selectedKeys?.length}
                  onClick={() => {
                    form.resetFields();
                    handleSelectedKeys("");
                  }}
                >
                  Tạo Lại
                </Button>
                <Button
                  type="button"
                  className="ant-btn ant-btn-primary ant-btn-sm"
                  htmlType="submit"
                >
                  OK
                </Button>
              </div>
            </Form>
          </div>
        );
      },
      onFilterDropdownOpenChange: (visible) => handleFirstClick(visible),
    };
  };
  itemRender.propTypes = {
    handleSelectedKeys: PropTypes.func,
    type: PropTypes.string,
    selectedKeys: PropTypes.any,
    placeholder: PropTypes.string,
  };
  return {
    getColumnSearchProps,
  };
};

export default useColumnSearch;
