/* eslint-disable no-unused-vars */
import { useMemo, useState, useEffect } from "react";
import { Avatar, Button, message, Upload, Image } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "./index.style.less";

const testImage = (url) => {
  const imgPromise = new Promise((resolve, reject) => {
    const tester = document.createElement("img");
    tester.addEventListener("error", () => reject());
    tester.addEventListener("load", () => resolve(this));
    tester.src = url;
  });
  return imgPromise;
};

const AppSingleImage = ({ value, onChange, isBanner = false, ...rest }) => {
  const [imageError, setImageError] = useState();
  useEffect(() => {
    if (value?.url)
      testImage(value?.url).then(
        () => setImageError(false),
        () => setImageError(true)
      );
    else setImageError(false);
  }, [value?.url]);
  const uploadProps = useMemo(
    () => ({
      listType: "picture",
      accept: "image/*",
      showUploadList: false,
      beforeUpload: (file) => {
        setImageError(false);
        const isJpgOrPng =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/webp";
        if (!isJpgOrPng) {
          message.error("Bạn chỉ có thể upload ảnh JPG/PNG/WEBP!");
          return isJpgOrPng || Upload.LIST_IGNORE;
        }
        return false;
      },
      onChange: ({ file }) => {
        setImageError(false);
        onChange(file);
      },
      customRequest: ({ onSuccess }) => {
        setTimeout(() => {
          onSuccess?.("ok");
        }, 0);
      },
    }),
    [value, onChange, setImageError]
  );
  return useMemo(() => {
    return (
      <>
        <Upload {...uploadProps}>
          {}
          {value?.uid && !imageError ? (
            <div onClick={(e) => e.stopPropagation()}>
              <span className="container-avatar">
                <Avatar
                  className={`avatar ${
                    isBanner ? "bannerCustom" : "imageCustom"
                  } `}
                  src={
                    value?.url
                      ? `${value?.url}?${new Date().getTime()}`
                      : URL.createObjectURL(value)
                  }
                />
                <span
                  onClick={() => {
                    onChange();
                  }}
                  className="icon-close-in-circle close"
                ></span>
              </span>
            </div>
          ) : (
            <Button
              className={`avatar ${isBanner ? "bannerCustom" : "imageCustom"}`}
              icon={<UploadOutlined />}
            >
              Upload
            </Button>
          )}
        </Upload>
        {rest["aria-invalid"] === "true" && (
          <p style={{ color: "red" }}>Vui lòng upload hình!</p>
        )}
      </>
    );
  }, [value, imageError, rest]);
};
export default AppSingleImage;
