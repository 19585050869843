import { RiDashboardLine, RiGroupLine } from "react-icons/ri";
import {
  AiOutlinePicture,
  AiOutlineUserAdd,
  AiOutlineSetting,
  AiOutlineFlag,
  AiOutlineTags,
  AiOutlineMail,
} from "react-icons/ai";
import { RiOrganizationChart } from "react-icons/ri";
import { RiArticleLine } from "react-icons/ri";
import { MdOutlineTopic, MdPostAdd } from "react-icons/md";
import { UserPermission } from "shared/constants/AppEnums";

const routesConfig = [
  {
    id: "dashboards",
    title: "Application",
    messageId: "sidebar.application",
    type: "group",
    children: [
      {
        id: "dashboards",
        title: "Dashboards",
        messageId: "sidebar.app.dashboards",
        icon: <RiDashboardLine />,
        path: "/dashboards",
      },
      {
        id: "emailAnalysis",
        title: "Emails",
        messageId: "sidebar.app.emailAnalysis",
        icon: <AiOutlineMail />,
        path: "/dashboards/email-analysis",
        scopes: [
          UserPermission.EMAIL_ANALYSIS_DELETE,
          UserPermission.EMAIL_ANALYSIS_VIEW,
        ],
      },
      {
        id: "emails",
        title: "Emails",
        messageId: "sidebar.app.emails",
        icon: <AiOutlineMail />,
        path: "/dashboards/emails",
        scopes: [
          UserPermission.EMAIL_CREATE,
          UserPermission.EMAIL_EDIT,
          UserPermission.EMAIL_DELETE,
        ],
      },
      {
        id: "banners",
        title: "Banners",
        messageId: "sidebar.app.banners",
        icon: <AiOutlinePicture />,
        path: "/dashboards/banners",
        scopes: [
          UserPermission.BANNER_CREATE,
          UserPermission.BANNER_EDIT,
          UserPermission.BANNER_DELETE,
        ],
      },
      {
        id: "categories",
        title: "Topics",
        messageId: "sidebar.app.category",
        icon: <MdOutlineTopic />,
        path: "/dashboards/categories",
        scopes: [
          UserPermission.CATEGORY_CREATE,
          UserPermission.CATEGORY_EDIT,
          UserPermission.CATEGORY_DELETE,
        ],
      },
      {
        id: "tags",
        title: "Tags",
        messageId: "sidebar.app.tags",
        icon: <AiOutlineTags />,
        path: "/dashboards/tags",
        scopes: [
          UserPermission.TAGS_CREATE,
          UserPermission.TAGS_EDIT,
          UserPermission.TAGS_DELETE,
        ],
      },
      {
        id: "groups",
        title: "Groups",
        messageId: "sidebar.app.groups",
        icon: <RiGroupLine />,
        path: "/dashboards/groups",
        scopes: [
          UserPermission.GROUPS_CREATE,
          UserPermission.GROUPS_EDIT,
          UserPermission.GROUPS_DELETE,
        ],
      },
      {
        id: "posts",
        title: "Posts",
        messageId: "sidebar.app.posts",
        icon: <RiArticleLine />,
        path: "/dashboards/posts",
        scopes: [
          UserPermission.POSTS_CREATE,
          UserPermission.POSTS_EDIT,
          UserPermission.POSTS_DELETE,
        ],
      },
      {
        id: "post-templates",
        title: "Templates",
        messageId: "sidebar.app.post.templates",
        icon: <MdPostAdd />,
        path: "/dashboards/post-templates",
        scopes: [
          UserPermission.POSTS_CREATE,
          UserPermission.POSTS_EDIT,
          UserPermission.POSTS_DELETE,
        ],
      },
      {
        id: "report",
        title: "Report",
        messageId: "sidebar.app.report",
        icon: <AiOutlineFlag />,
        path: "/dashboards/report",
        scopes: [UserPermission.REPORT_ACTION, UserPermission.REPORT_DELETE],
      },
      {
        id: "users",
        title: "Users",
        messageId: "sidebar.app.users",
        icon: <AiOutlineUserAdd />,
        path: "/dashboards/users",
        scopes: [
          UserPermission.USERS_CREATE,
          UserPermission.USERS_EDIT,
          UserPermission.USERS_DELETE,
        ],
      },
      {
        id: "roles",
        title: "Toles",
        messageId: "sidebar.app.roles",
        icon: <RiOrganizationChart />,
        path: "/dashboards/roles",
        scopes: [
          UserPermission.ROLES_CREATE,
          UserPermission.ROLES_EDIT,
          UserPermission.ROLES_DELETE,
        ],
      },
      {
        id: "settings",
        title: "Settings",
        messageId: "sidebar.app.settings",
        icon: <AiOutlineSetting />,
        path: "/dashboards/settings",
        scopes: [
          UserPermission.SETTINGS_CREATE_RULE,
          UserPermission.SETTINGS_DELETE_RULE,
          UserPermission.SETTINGS_EDIT_INFORMATION,
          UserPermission.SETTINGS_EDIT_RULE,
        ],
      },
    ],
  },
];
export default routesConfig;
