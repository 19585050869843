import useSWR from "swr";
import { URL_API } from "@cafefinz/services/endpoint";
import { getAllPermissions } from "@cafefinz/services/apis/roles";

const useGetAllPermissions = () => {
  const swrData = useSWR(
    {
      url: URL_API.all,
    },
    getAllPermissions
  );
  return {
    ...swrData,
    data: swrData.data ?? [],
  }
};

export default useGetAllPermissions;
