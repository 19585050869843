import { useState, useCallback, useMemo, useEffect } from "react";
import { AppRowContainer } from "@cafefinz";
import IntlMessages from "@cafefinz/utility/IntlMessages";
import {
  Form,
  Modal,
  Button,
  Col,
  Input,
  InputNumber,
  Select,
  Checkbox,
  DatePicker,
  Spin,
  Empty,
  TimePicker,
} from "antd";
import { AppInputType } from "shared/constants/AppEnums";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import AppSingleImage from "@cafefinz/core/AppFileList/AppSingleImage";
import AppMultiImage from "@cafefinz/core/AppFileList/AppMultiImage";
import "./index.style.less";
// import { useInView } from "react-intersection-observer";

const { Option } = Select;

const withCustomSelect = (SelectComponent) => (props) => {
  const [isFirstDropdownOpen, setIsFirstDropdownOpen] = useState(false);
  const { fetchMoreData, loading } = props;
  const onDropdownVisibleChangeHandle = (isOpen) => {
    if (isOpen && !isFirstDropdownOpen) setIsFirstDropdownOpen(true);
  };
  useEffect(() => {
    if (isFirstDropdownOpen) fetchMoreData?.();
  }, [isFirstDropdownOpen]);
  return (
    <SelectComponent
      onDropdownVisibleChange={onDropdownVisibleChangeHandle}
      onPopupScroll={(e) => {
        const { scrollTop, offsetHeight, scrollHeight } = e.target;
        const isReachingEnd = scrollTop + offsetHeight === scrollHeight;
        if (isReachingEnd && !loading) void fetchMoreData?.();
      }}
      dropdownRender={(menu) => {
        return (
          <>
            {menu} {loading && <Spin size="small" />}
          </>
        );
      }}
      notFoundContent={!loading && <Empty />}
      allowClear
      showArrow
      {...props}
    />
  );
};

const CustomSelect = withCustomSelect(Select);

export const FieldItem = ({ fieldConfig, data }) => {
  const form = Form.useFormInstance();
  const {
    name,
    label,
    render,
    type,
    placeholder,
    options,
    maxLength,
    rules,
    fieldCol,
    labelSpan,
    fetchMoreData,
    isReachingEnd,
    isBanner,
    defaultValue,
    prefix,
    onValueChange,
    searchOption,
    loading,
    isFilterSelectDataExists,
    format,
    showTime,
    disabledDate,
    disabledTime,
    autoSize,
  } = fieldConfig ?? {};

  const onFieldValueChange = useCallback(
    (value) => onValueChange?.(value, form),
    [form]
  );

  const fieldInputContent = useMemo(() => {
    switch (type) {
      case AppInputType.TEXT:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: labelSpan ?? 8 }}
              rules={rules}
              name={name}
              label={label}
            >
              <Input
                prefix={prefix}
                maxLength={maxLength}
                showCount={maxLength && true}
                placeholder={placeholder}
                value={data?.[name]}
                onChange={onFieldValueChange}
              />
            </Form.Item>
          </Col>
        );
      case AppInputType.TEXT_AREA:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: labelSpan ?? 8 }}
              rules={rules}
              name={name}
              label={label}
            >
              <Input.TextArea
                rows={5}
                placeholder={placeholder}
                value={data?.[name]}
                maxLength={maxLength}
                showCount
                onChange={onFieldValueChange}
                autoSize={autoSize}
              />
            </Form.Item>
          </Col>
        );
      case AppInputType.NUMBER:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: labelSpan ?? 8 }}
              rules={rules}
              name={name}
              label={label}
            >
              <InputNumber
                placeholder={placeholder}
                name={name}
                value={data?.[name]}
                onChange={onFieldValueChange}
              />
            </Form.Item>
          </Col>
        );
      case AppInputType.PASSWORD:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: labelSpan ?? 8 }}
              hasFeedback
              rules={rules}
              name={name}
              label={label}
            >
              <Input.Password
                placeholder={placeholder}
                value={data?.[name]}
                onChange={onFieldValueChange}
                autoComplete="new-password"
              />
            </Form.Item>
          </Col>
        );
      case AppInputType.SELECT:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: labelSpan ?? 8 }}
              rules={rules}
              name={name}
              label={label}
            >
              <CustomSelect
                placeholder={placeholder}
                defaultValue={defaultValue}
                maxTagCount="responsive"
                onSelect={onFieldValueChange}
                onSearch={searchOption}
                showSearch
                optionFilterProp="label"
                filterOption={
                  isFilterSelectDataExists
                    ? (input, option) =>
                        (option?.children?.props?.children ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                    : false
                }
                fetchMoreData={fetchMoreData}
                loading={loading}
              >
                {options?.map((option, index) => {
                  const { name, value } = option;
                  return (
                    <Option key={index} value={value}>
                      <span>{name}</span>
                    </Option>
                  );
                })}
              </CustomSelect>
            </Form.Item>
          </Col>
        );
      case AppInputType.UPLOAD_SINGLE_IMAGE:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: labelSpan ?? 8 }}
              rules={rules}
              name={name}
              label={label}
            >
              <AppSingleImage isBanner={isBanner} />
            </Form.Item>
          </Col>
        );
      case AppInputType.UPLOAD_MULTI_IMAGE:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: labelSpan ?? 8 }}
              rules={rules}
              name={name}
              label={label}
            >
              <AppMultiImage />
            </Form.Item>
          </Col>
        );
      case AppInputType.EDITOR:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: labelSpan ?? 8 }}
              rules={rules}
              name={name}
              label={label}
              initialValue=""
            >
              <ReactQuill
                onChange={onFieldValueChange}
                theme="snow"
                placeholder={placeholder}
              />
            </Form.Item>
          </Col>
        );
      case AppInputType.CHECKBOX:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: labelSpan ?? 8 }}
              rules={rules}
              name={name}
              label={label}
              valuePropName="checked"
            >
              <Checkbox onChange={onFieldValueChange} />
            </Form.Item>
          </Col>
        );
      case AppInputType.MULTISELECT:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: labelSpan ?? 8 }}
              name={name}
              label={label}
            >
              <CustomSelect
                onSelect={onFieldValueChange}
                maxTagCount="responsive"
                mode="multiple"
                className={"selected-group"}
                placeholder={placeholder}
                onSearch={searchOption}
                optionFilterProp="label"
                filterOption={
                  isFilterSelectDataExists
                    ? (input, option) =>
                        (option?.children?.props?.children ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                    : false
                }
                fetchMoreData={fetchMoreData}
                loading={loading}
              >
                {options?.map((option, index) => {
                  const { name, value } = option;
                  return (
                    <Option key={index} value={value}>
                      <span>{name}</span>
                    </Option>
                  );
                })}
              </CustomSelect>
            </Form.Item>
          </Col>
        );
      case AppInputType.DATE_PICKER:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: labelSpan ?? 8 }}
              rules={rules}
              name={name}
              label={label}
            >
              <DatePicker
                onChange={onFieldValueChange}
                className="date-picker"
                format={format ?? "DD/MM/YYYY"}
                showTime={showTime ?? false}
                disabledDate={disabledDate}
                disabledTime={disabledTime}
              />
            </Form.Item>
          </Col>
        );
      case AppInputType.TIME_PICKER:
        return (
          <Col sm={fieldCol ?? 24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ span: labelSpan ?? 8 }}
              rules={rules}
              name={name}
              label={label}
            >
              <TimePicker
                onChange={onFieldValueChange}
                className="time-picker"
              />
            </Form.Item>
          </Col>
        );
      default:
        return null;
    }
  }, [
    type,
    options,
    fetchMoreData,
    isReachingEnd,
    placeholder,
    name,
    form,
    isBanner,
    onValueChange,
    searchOption,
    isFilterSelectDataExists,
  ]);

  if (render) {
    return <>{render(data, form)}</>;
  }

  return <>{fieldInputContent}</>;
};

FieldItem.propTypes = {
  fieldConfig: PropTypes.object,
  data: PropTypes.object,
  form: PropTypes.object,
};
FieldItem.defaultProps = {
  fieldConfig: {},
  data: {},
  form: {},
  rules: [],
};

const useAppCreateOrEditModal = ({
  fieldsConfig,
  onFinish,
  title,
  data,
  loading,
  width,
}) => {
  const [form] = Form.useForm();

  const [initialData, setInitialData] = useState();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const isInitialDataChange =
      JSON.stringify(initialData) !== JSON.stringify(data);
    if (isInitialDataChange) {
      setInitialData(data);
      form.resetFields();
      form.setFieldsValue(data);
    }
  }, [data, form]);

  const openModal = useCallback(() => {
    setIsVisible(true);
  }, [setIsVisible, form]);

  const closeModal = useCallback(() => {
    setIsVisible(false);
    form.resetFields();
    setInitialData("");
  }, [setIsVisible, setInitialData, form]);

  const contextHolder = useMemo(() => {
    return (
      <Modal
        open={isVisible}
        onCancel={closeModal}
        footer={null}
        width={width || 600}
        title={title}
      >
        <div>
          <Form
            onFinish={(values) => {
              onFinish(values);
            }}
            form={form}
            scrollToFirstError
          >
            <AppRowContainer gutter={16}>
              {fieldsConfig.map((fieldConfig, index) => (
                <FieldItem key={index} fieldConfig={fieldConfig} data={data} />
              ))}
            </AppRowContainer>
            <div className="ant-modal-footer">
              <Button type="primary" ghost onClick={closeModal}>
                <IntlMessages id="common.cancel" />
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                <IntlMessages id="common.save" />
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    );
  }, [closeModal, isVisible, title, fieldsConfig, data]);
  return {
    openModal,
    closeModal,
    contextHolder,
  };
};

export default useAppCreateOrEditModal;
