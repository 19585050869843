import { useCallback } from "react";
import useSWRMutation from "swr/mutation"
import { URL_API } from "@cafefinz/services/endpoint";
import { createRole } from "@cafefinz/services/apis/roles";

const useCreateRole = () => {
  const createData = useCallback((_url, { arg }) => createRole({
    ...arg,
    userIds: arg?.users,
    permissionRoles: arg?.permissionRoles?.map(value => ({
      permissionId: value,
    }))
  }), [createRole])

  return useSWRMutation(URL_API.POST_ROLE, createData);
};

export default useCreateRole;
