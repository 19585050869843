import axios from "axios";

const putFileToS3 = async (s3Link, file) => {
  const data = await axios({
    method: "PUT",
    url: s3Link,
    data: file,
  });
  return data;

};

export { putFileToS3 };
