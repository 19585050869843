import jwtAxios from "@cafefinz/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@cafefinz/services/endpoint";

const getListPostService = async (params) => {
  const { data } = await jwtAxios.get(URL_API.GET_LIST_POST, { params });
  return data;
};

const getPostByIdService = async (params) => {
  const { data } = await jwtAxios.get(URL_API.GET_POST_BY_ID, { params });
  return data;
};

const createPostService = async (formData) => {
  const { data } = await jwtAxios.post(URL_API.CREATE_POST, formData);
  return data;
};

const deletePostService = async (formData) => {
  const { data } = await jwtAxios.delete(URL_API.DELETE_POST, {
    data: { ids: formData },
  });
  return data;
};

const updatePostService = async ({ id, formData }) => {
  const { data } = await jwtAxios.patch(
    `${URL_API.UPDATE_POST}/${id}`,
    formData
  );
  return data;
};

const getInternalUsersService = async (params) => {
  const { data } = await jwtAxios.get(URL_API.GET_INTERNAL_USERS, { params });
  return data;
};

const getTagInGroupService = async (params) => {
  const { data } = await jwtAxios.get(URL_API.GET_TAGS_IN_GROUP, { params });
  return data;
};

const createCommentService = async (formData) => {
  const { data } = await jwtAxios.post(URL_API.CREATE_COMMENT, formData);
  return data;
};

const getLinkImageS3Service = async (value) => {
  const response = await jwtAxios.post(URL_API.GET_IMAGE_LINK_S3, value);
  return response;
};

const deleteLinkImageS3Service = async (value) => {
  const response = await jwtAxios.delete(URL_API.DETELE_IMAGE_LINK_S3, {
    data: value,
  });
  return response;
};

const moveFileS3Service = async (value) => {
  const response = await jwtAxios.post(URL_API.MOVE_FILE_IMAGE_S3, value);
  return response;
};

export {
  getListPostService,
  getPostByIdService,
  createPostService,
  deletePostService,
  updatePostService,
  getInternalUsersService,
  createCommentService,
  getTagInGroupService,
  getLinkImageS3Service,
  deleteLinkImageS3Service,
  moveFileS3Service,
};
