import saMessages from "../locales/ar_SA.json";
import arEG from "antd/lib/locale/ar_EG";

const saLang = {
	messages: {
		...saMessages,
	},
	antLocale: arEG,
	locale: "ar-SA",
};
export default saLang;
