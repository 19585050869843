import React from "react";
import useSWR from "swr";
import { URL_API } from "@cafefinz/services/endpoint";
import { getListRoles } from "@cafefinz/services/apis/roles";

const useGetListRole = (params) => {
  const getData = React.useCallback(
    ({ params }) => getListRoles(params),
    [getListRoles]
  );

  return useSWR(
    {
      url: URL_API.GET_LIST_ROLES,
      params,
    },
    getData,
    { keepPreviousData: true }
  );
};

export default useGetListRole;
