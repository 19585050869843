/* eslint-disable react/prop-types */
import {memo} from "react";
import { useAuthUser } from "../../utility/AuthHooks";
import { Button } from "antd";
import { checkPermissionGranted } from "@cafefinz/utility/Utils";

const ButtonPermission = (props) => {
	const { user } = useAuthUser();
	const isGranted = checkPermissionGranted({permissions: user.permissions, scopes: props.scopes})
	return isGranted ? <Button {...props} /> : <></>;
}

export default memo(ButtonPermission);