import jwtAxios from "@cafefinz/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@cafefinz/services/endpoint";

const getListRoles = async (params) => {
  try {
    const { data } = await jwtAxios.get(URL_API.GET_LIST_ROLES, { params });
    return data;

  } catch (error) {
    console.log("error", error);
    throw error
  }
};
const createRole = async (formData) => {
  try {
    const response = await jwtAxios.post(URL_API.POST_ROLE, formData);
    return response;
  } catch (error) {
    console.log("error", error);
    throw error
  }
};

const updateRole = async ({ id, formData }) => {
  try {
    const response = await jwtAxios.patch(
      `${URL_API.UPDATE_ROLE}/${id}`,
      formData
    );
    return response;
  } catch (error) {
    console.log("error", error);
    throw error
  }
};

const deleteRole = async (roleIds) => {
  try {
    const response = await jwtAxios.delete(URL_API.DELETE_ROLE, {
      data: { ids: roleIds },
    });
    return response;
  } catch (error) {
    console.log("error", error);
    throw error
  }
};

const getRoleForEdit = async (idRole) => {
  try {
    const { data } = await jwtAxios.get(
      `${URL_API.GET_ROLE_FOR_EDIT}?id=${idRole}`
    );
    return data;
  } catch (error) {
    console.log("error", error);
    throw error
  }
};

const getAllPermissions = async () => {
  try {
    const { data } = await jwtAxios.get(URL_API.GET_ALL_PERMISSIONS);
    return data;
  } catch (error) {
    console.log("error", error);
    throw error
  }
};

const getUserByRoleId = async (params) => {
  const { data } = await jwtAxios.get(URL_API.GET_USER_BY_ROLE_ID, {
    params,
  });
  return data;
};

export {
  getListRoles,
  createRole,
  updateRole,
  deleteRole,
  getRoleForEdit,
  getAllPermissions,
  getUserByRoleId,
};
