import jwtAxios from "@cafefinz/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@cafefinz/services/endpoint";

const getListUsers = async (params) => {
  const { data } = await jwtAxios.get(URL_API.GET_LIST_USERS, { params });
  return data;
};

const getUserToView = async (id) => {
  const { data } = await jwtAxios.get(`${URL_API.GET_USER_TO_VIEW}?id=${id}`);
  return data;
};

const getListNations = async () => {
  const { data } = await jwtAxios.get(URL_API.GET_LIST_NATIONS);
  return data;
};

const getThreadsByUserId = async (params) => {
  const { data } = await jwtAxios.get(URL_API.GET_THREADS_BY_USER_ID, {
    params,
  });
  return data;
};

const getGroupsByUserId = async (params) => {
  const { data } = await jwtAxios.get(URL_API.GET_GROUP_BY_USER_ID, {
    params,
  });
  return data;
};

export {
  getListUsers,
  getUserToView,
  getListNations,
  getThreadsByUserId,
  getGroupsByUserId,
};
