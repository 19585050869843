import { EmptyValue } from "hooks/useColumnSearch";
import moment from "moment";
import {
  authRole,
  BannerMediaType,
  S3Type,
  ThreadType,
  bannerFilter,
  bannerFilterType,
  BannerType,
} from "../../shared/constants/AppEnums";

export const createRoutes = (routeConfigs) => {
  let allRoutes = [];
  routeConfigs.forEach((config) => {
    allRoutes = [...allRoutes, ...setRoutes(config)];
  });
  return allRoutes;
};

export const setRoutes = (config) => {
  let routes = [...config.routes];
  if (config.auth) {
    routes = routes.map((route) => {
      let auth = route.auth
        ? [...config.auth, ...route.auth]
        : [...config.auth];
      return { ...route, auth };
    });
  }

  return [...routes];
};

export const getBreakPointsValue = (valueSet, breakpoint) => {
  if (typeof valueSet === "number") return valueSet;
  switch (breakpoint) {
    case "xs":
      return valueSet.xs;
    case "sm":
      return valueSet.sm || valueSet.xs;
    case "md":
      return valueSet.md || valueSet.sm || valueSet.xs;
    case "lg":
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
    default:
      return (
        valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
      );
  }
};

export const getFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";
  let k = 1024,
    dm = 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const multiPropsFilter = (products, filters, stringKey = "title") => {
  const filterKeys = Object.keys(filters);
  return products.filter((product) => {
    return filterKeys.every((key) => {
      if (!filters[key].length) return true;
      // Loops again if product[key] is an array (for material attribute).
      if (Array.isArray(product[key])) {
        return product[key].some((keyEle) => filters[key].includes(keyEle));
      }
      console.log("key", key, filters[key], product[key]);
      if (key === stringKey) {
        return product[key].toLowerCase().includes(filters[key].toLowerCase());
      }
      return filters[key].includes(product[key]);
    });
  });
};

export const getCustomDateTime = (
  value = 0,
  unit = "days",
  format = "YYYY-MM-DD"
) => {
  if (value === 0) {
    return moment().format(format);
  } else {
    return moment().add(value, unit).format(format);
  }
};

export const timeFromNow = (date) => {
  const timestamp = moment(date).format("X");
  const newDate = moment.unix(timestamp);
  return moment(newDate).fromNow();
};

export const checkPermission = (routeAuth, userRole) => {
  if (routeAuth === null || routeAuth === undefined) {
    return true;
  }

  if (userRole && Array.isArray(userRole)) {
    return routeAuth.some((r) => userRole.indexOf(r) >= 0);
  }

  if (routeAuth.length === 0) {
    return !userRole || userRole.length === 0;
  }
  if (userRole && Array.isArray(userRole) && Array.isArray(routeAuth)) {
    return routeAuth.some((r) => userRole.indexOf(r) >= 0);
  }
  return routeAuth.indexOf(userRole) >= 0;
};

export const checkPermissionGranted = ({ permissions = [], scopes = [] }) => {
  return permissions.some(
    (permission) =>
      !scopes || scopes.length === 0 || scopes.includes(permission)
  );
};

export const filterPermissionGrantedList = ({
  permissions = [],
  listItems = [],
}) => {
  return listItems.filter((item) =>
    checkPermissionGranted({ permissions, scopes: item.scopes })
  );
};

export const generateUniqueID = () => {
  return `v1-${Date.now()}-${Math.floor(Math.random() * (9e12 - 1)) + 1e12}`;
};

export const getUserFromAuth0 = (user) => {
  if (user)
    return {
      id: 1,
      uid: user.sub,
      displayName: user.name,
      email: user.email,
      photoURL: user.picture,
      role: authRole.user,
    };
  return user;
};

export const getUserFromFirebase = (user) => {
  if (user)
    return {
      id: 1,
      uid: user.uid,
      displayName: user.displayName ? user.displayName : "Sky User",
      email: user.email,
      photoURL: user.photoURL,
      role: authRole.user,
    };
  return user;
};
export const getUserFromAWS = (user) => {
  if (user)
    return {
      id: 1,
      uid: user.username,
      displayName: user.attributes.name ? user.attributes.name : "Sky User",
      email: user.attributes.email,
      photoURL: user.photoURL,
      role: authRole.user,
    };
  return user;
};

export const getUserFromJwtAuth = (user) => {
  if (user)
    return {
      id: 1,
      uid: user._id,
      displayName: user.name,
      email: user.email,
      photoURL: user.avatar,
      role: authRole.user,
    };
  return user;
};

export const convertToS3Link = (type, id, s3String) => {
  return s3String?.startsWith("defaultPics_")
    ? process.env.REACT_APP_LINK_S3 + s3String + ".png"
    : process.env.REACT_APP_LINK_S3 + type + "_" + id + "/" + s3String;
};

export const getDateFromTimeStamp = (timeStampFormat) => {
  if (!timeStampFormat) return timeStampFormat;
  const dateAndTime = timeStampFormat.split("T");
  const date = dateAndTime[0].split("-");
  return date[2] + "/" + date[1] + "/" + date[0];
};

export const convertDateTime = (timeStampFormat) => {
  return timeStampFormat && moment(timeStampFormat).format("DD/MM/YYYY h:mmA");
};

export const convertGender = (number) => {
  switch (number) {
    case 1:
      return "MALE";
    case 2:
      return "FEMALE";
    case 3:
      return "OTHER";
    default:
      return number;
  }
};

export const convertBannerPosition = (position) => {
  if (!position) return bannerFilter.BANNER_LOCATION;
  const positions = position.split(";").map((pos) => {
    switch (pos) {
      case bannerFilterType.POST:
        return bannerFilter.BANNER_POST;
      case bannerFilterType.NOTIFICATION:
        return bannerFilter.BANNER_NOTIFICATION;
      default:
        return null;
    }
  });
  return positions.filter((pos) => pos !== "").join(", ");
};

export const convertBannerActiveType = (type) => {
  switch (type) {
    case bannerFilterType.TRUE:
      return bannerFilter.BANNER_TRUE;
    case bannerFilterType.FALSE:
      return bannerFilter.BANNER_FALSE;
    default:
      return bannerFilter.BANNER_STATUS;
  }
};

export const convertBannerMediaDataToParam = (mediaType, mediaData = []) => {
  switch (mediaType) {
    case BannerMediaType.IMAGE:
      return mediaData.map((image) => ({
        content: image?.uid,
        type: BannerMediaType.IMAGE,
      }));
    case BannerMediaType.VIDEO:
    default:
      return [
        {
          content: mediaData,
          type: BannerMediaType.VIDEO,
        },
      ];
  }
};

export const convertBannerMediaDataToFormValue = (
  bannerId,
  mediaType,
  bannerMedias = []
) => {
  switch (mediaType) {
    case BannerMediaType.IMAGE:
      return bannerMedias.map((media) => ({
        uid: media?.content,
        name: media?.content,
        // eslint-disable-next-line no-undef
        url: convertToS3Link(S3Type.BID, bannerId, media?.content),
      }));
    case BannerMediaType.VIDEO:
      return bannerMedias.map((media) => media.content).join("");
    default:
      return bannerMedias;
  }
};

export const convertThreadGalleriesToParam = (threadGalleries, type) => {
  if (!threadGalleries || !threadGalleries?.length) return [];
  switch (type) {
    case ThreadType.SURVEY:
    case ThreadType.IMAGE:
      return threadGalleries?.map(({ uid, name, width, height }, index) => ({
        id: uid,
        link: uid,
        name: uid,
        description: name,
        visibleOrder: index,
        type: "image",
        width,
        height,
      }));
    case ThreadType.UPLOAD_VIDEO:
      return threadGalleries?.map(
        ({ uid, name, type, width, height }, index) => {
          const extension = type ? getExtension(type ?? "type/mp4") : "";
          return {
            id: uid,
            link: uid.endsWith(extension) ? uid : uid + extension,
            name: uid.endsWith(extension) ? uid : uid + extension,
            description: name,
            visibleOrder: index,
            type: "video",
            width,
            height,
          };
        }
      );
    case ThreadType.VIDEO:
    default:
      return [
        {
          link: threadGalleries,
          name: threadGalleries,
          description: threadGalleries,
        },
      ];
  }
};
export const convertBannerTypeToText = (type) => {
  switch (type) {
    case BannerMediaType.IMAGE:
      return BannerType.IMAGE;
    case BannerMediaType.VIDEO:
    default:
      return BannerType.VIDEO;
  }
};

export const convertThreadTypeToText = (type) => {
  switch (type) {
    case ThreadType.VIDEO:
      return "Chèn link video";
    case ThreadType.SURVEY:
      return "Khảo sát";
    case ThreadType.TEMPLATE:
      return "Mẫu";
    case ThreadType.IMAGE:
      return "Chữ";
    case ThreadType.UPLOAD_VIDEO:
      return "Tải lên Video";
    default:
      return "Chữ";
  }
};

export const convertTypeFromApi = (type) => {
  switch (type) {
    case ThreadType.VIDEO:
      return ThreadType.VIDEO;
    case ThreadType.IMAGE:
      return ThreadType.IMAGE;
    case ThreadType.SURVEY:
      return ThreadType.SURVEY;
    // case ThreadType.ONLY_TEXT:
    //   return ThreadType.ONLY_TEXT;
    case ThreadType.TEMPLATE:
      return ThreadType.TEMPLATE;
    case ThreadType.TEXT_LINK:
      return ThreadType.TEXT_LINK;
    case ThreadType.UPLOAD_VIDEO:
      return ThreadType.UPLOAD_VIDEO;
    default:
      return ThreadType.IMAGE;
  }
};

export const convertThreadTypeToParams = ({ threadGalleries, type }) => {
  switch (type) {
    case ThreadType.IMAGE:
      if (threadGalleries?.length) {
        return ThreadType?.IMAGE.toString();
      } else {
        return ThreadType?.ONLY_TEXT.toString();
      }
    case ThreadType.VIDEO:
      return ThreadType.VIDEO.toString();
    case ThreadType.SURVEY:
      return ThreadType.SURVEY.toString();
    case ThreadType.TEMPLATE:
      return ThreadType.TEMPLATE.toString();
    case ThreadType.UPLOAD_VIDEO:
      return ThreadType.VIDEO.toString();
  }
};

export const ignoreEmptyValue = (data) => {
  return data
    ?.split(",")
    ?.filter((item) => item !== EmptyValue)
    .toString();
};

export const formatDate = (date) => {
  let dateMoment;
  try {
    dateMoment = moment(date);
  } catch (e) {
    dateMoment = moment();
  }

  return dateMoment.subtract(1, "days").format();
};

export const formatTime = (time) => {
  let timeMoment;
  try {
    timeMoment = moment(time);
  } catch (e) {
    timeMoment = moment();
  }

  return timeMoment.subtract(1, "days").format("H:MM");
};

export const removeCfzPrefix = (string) => string.replace("cfz/", "");

export const convertContentToText = (content, deleteSpaceInside = true) => {
  if (!content) return "";
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");

  return deleteSpaceInside
    ? doc
        ?.querySelector("body")
        ?.innerHTML?.replace(/<\/?[^>]+(>|$)/g, " ")
        ?.replace(/\s+/g, " ")
        ?.trim()
        ?.toString()
    : doc
        ?.querySelector("body")
        ?.innerHTML?.replace(/<\/?[^>]+(>|$)/g, " ")
        ?.trim()
        ?.toString();
};

export const getExtension = (fileType) => {
  const splitType = fileType.split("/");
  if (splitType[0] === "video") {
    switch (splitType[1]) {
      case "mp4":
        return ".mp4";
      case "webm":
        return ".webm";
      case "ogg":
        return ".ogg";
      case "mpeg":
        return ".mpeg";
      case "quicktime":
        return ".mov";
      case "x-msvideo":
        return ".avi";
      case "x-ms-wmv":
        return ".wmv";
      default:
        return ".mp4";
    }
  }
  return "";
};

// Function to get image dimensions
export const getImageDimensions = (file) => {
  return new Promise((resolve, reject) => {
    if (!file.originFileObj) {
      reject(new Error("File object is not defined"));
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file.originFileObj);

    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      resolve({ width, height });
    };

    img.onerror = () => {
      reject(new Error("Failed to load image"));
    };
  });
};

export const getVideoDimensions = (file) => {
  return new Promise((resolve, reject) => {
    if (!file.originFileObj) {
      reject(new Error("File object is not defined"));
      return;
    }

    const video = document.createElement("video");
    video.src = URL.createObjectURL(file.originFileObj);

    video.onloadedmetadata = () => {
      const width = video.videoWidth;
      const height = video.videoHeight;
      resolve({ width, height });
    };

    video.onerror = () => {
      reject(new Error("Failed to load video"));
    };
  });
};

