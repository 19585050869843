import React, { useCallback, useEffect, useMemo } from "react";
import useSWRInfinite from "swr/infinite";
import { URL_API } from "@cafefinz/services/endpoint";
import { getListUsers } from "@cafefinz/services/apis/user";
import { TakeDefault } from "shared/constants/AppConst";
import useDebounce from "hooks/useDebounce";

const useGetInfinityUsers = (params) => {
  const { debouncing, debouncedValue } = useDebounce(params?.user, 300);
  const getData = React.useCallback(
    ({ page, uniqueUrl }) =>
      getListUsers({
        page,
        take: TakeDefault,
        uniqueUrl,
      }),
    [getListUsers]
  );
  const getKey = (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.data.length) return null;
    return {
      url: URL_API.GET_LIST_USERS,
      page: pageIndex + 1,
      uniqueUrl: debouncedValue,
    };
  };
  const { data, mutate, error, isLoading, isValidating, setSize } =
    useSWRInfinite(getKey, getData, {
      revalidateFirstPage: false,
      initialSize: 0,
    });
  const listUsers = useMemo(
    () => (data ? data.flatMap((page) => page.data) : []),
    [data]
  );
  const isReachingEndUsers =
    listUsers?.[0]?.length === 0 ||
    (listUsers && listUsers[listUsers.length - 1]?.length < 10);
  const fetchMoreUsers = useCallback(
    () => setSize((size) => size + 1),
    [setSize]
  );
  useEffect(() => {
    if (debouncedValue) void fetchMoreUsers?.();
  }, [debouncedValue, fetchMoreUsers]);

  return {
    listUsers,
    isReachingEndUsers,
    fetchMoreUsers,
    mutateTags: mutate,
    error,
    isLoadingUsers: isLoading || debouncing || isValidating,
    isValidating,
  };
};

export default useGetInfinityUsers;
