import React from "react";
import { Provider } from "react-redux";

import "./shared/styles/crema.less";
import {
  AppContextProvider,
  AppLayout,
  AppLocaleProvider,
  AppThemeProvider,
  AuthRoutes,
} from "./@cafefinz";
import configureStore from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import "./@cafefinz/services/index";
import JWTAuthAuthProvider from "@cafefinz/services/auth/jwt-auth/JWTAuthProvider";
import { SWRConfig } from "swr";

const store = configureStore();
const swrConfig = {
  revalidateOnFocus: false,
  shouldRetryOnError: false,
  revalidateIfStale: false,
};

const App = () => (
  <AppContextProvider>
    <Provider store={store}>
      <SWRConfig value={swrConfig}>
        <AppThemeProvider>
          <AppLocaleProvider>
            <BrowserRouter>
              <JWTAuthAuthProvider>
                <AuthRoutes>
                  <AppLayout />
                </AuthRoutes>
              </JWTAuthAuthProvider>
            </BrowserRouter>
          </AppLocaleProvider>
        </AppThemeProvider>
      </SWRConfig>
    </Provider>
  </AppContextProvider>
);

export default App;
