import React, { useMemo } from "react";
import useSWRMutation from "swr/mutation"
import { URL_API } from "@cafefinz/services/endpoint";
import { getRoleForEdit } from "@cafefinz/services/apis/roles";

const useGetRoleForEdit = () => {
  const getData = React.useCallback((_url, { arg }) => getRoleForEdit(arg), [getRoleForEdit]);
  const { trigger, data } = useSWRMutation(URL_API.GET_ROLE_FOR_EDIT, getData);
  const formattedData = useMemo(() => {
    return {
      ...data,
        permissionRoles: data?.permissionRoles?.filter(permissionRole => !permissionRole?.deletedBy)?.map(permissionRole => permissionRole?.permission?.id),
        users: Object.values(data?.users ?? [])
          ?.map(( user ) => ({
            value: user?.id,
            label: user?.uniqueUrl,
          })),
    }
  }, [data]);
  return {
    triggerGetRoleById: trigger,
    dataGetRoleById: formattedData,
  };
};


export default useGetRoleForEdit;