import { useCallback, useEffect, useMemo, useState } from "react";
import { message, Upload } from "antd";
import {
  deleteLinkImageS3Service,
  getLinkImageS3Service,
} from "@cafefinz/services/apis/posts";
import { putFileToS3 } from "@cafefinz/services/apis/s3";
import { debounce } from "lodash";

const AppMultiImage = ({
  value,
  onChange,
  currentGroupId,
  isEdit,
  editGroupId,
  isCheckCloseModalThread,
  setIsCheckDeleteThread,
}) => {
  const [dataFetched, setDataFetched] = useState(true);
  const uploadNewImage = value?.filter(
    (item) => item.originFileObj !== undefined
  );

  const imageName = value
    ?.filter((item) => item.originFileObj !== undefined)
    .map((item) => item.originFileObj.uid);
  const [previousGroupId, setPreGroupId] = useState(currentGroupId);
  const [previousGroupImage, setPreviousGroupImage] = useState(null);

  const debouncedSetDataFetched = useCallback(
    debounce(() => {
      setDataFetched(true);
    }, 500),
    []
  );

  const fetchData = async (groupIdForFetch, imageNamForFetchData) => {
    try {
      const data = await getLinkImageS3Service({
        s3ImageNames: imageName,
        groupId: groupIdForFetch,
      });
      if (data.data.data) {
        data.data.data.forEach(async (s3Link, index) => {
          await putFileToS3(s3Link, imageNamForFetchData[index].originFileObj);
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deleteImageS3 = async (groupIdForDelete, imageNameForDelete) => {
    try {
      await deleteLinkImageS3Service({
        keyDelete: imageNameForDelete,
        groupId: groupIdForDelete,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [isDeleteOne, setIsDeleteOne] = useState(true);

  useEffect(() => {
    if (
      currentGroupId &&
      imageName.length > 0 &&
      isCheckCloseModalThread &&
      isDeleteOne
    ) {
      deleteImageS3(currentGroupId, imageName);
      setIsDeleteOne(false);
      () => setIsCheckDeleteThread(true);
    }
  }, [value, fetchData, currentGroupId, imageName, dataFetched]);

  if (editGroupId && imageName.length > 0 && dataFetched && isEdit) {
    fetchData(editGroupId, uploadNewImage);
    setDataFetched(false);
    setPreGroupId(currentGroupId);
    setPreviousGroupImage(imageName);
  }
  useEffect(() => {
    if (currentGroupId && imageName.length > 0 && dataFetched) {
      fetchData(currentGroupId, value);
      setDataFetched(false);
      setPreGroupId(currentGroupId);
      setPreviousGroupImage(imageName);
    }
  }, [value, fetchData, currentGroupId, imageName, dataFetched]);

  useEffect(() => {
    if (
      previousGroupId &&
      previousGroupImage &&
      previousGroupImage?.length !== imageName?.length &&
      previousGroupImage?.length > 0 &&
      imageName.length > 0
    ) {
      deleteImageS3(previousGroupId, previousGroupImage);
      debouncedSetDataFetched();
    }
  }, [value, currentGroupId, previousGroupId, previousGroupImage, imageName]);

  useEffect(() => {
    if (
      currentGroupId &&
      previousGroupId &&
      previousGroupImage &&
      previousGroupId !== currentGroupId &&
      previousGroupImage?.length > 0 &&
      imageName.length > 0
    ) {
      deleteImageS3(previousGroupId, previousGroupImage);
      debouncedSetDataFetched();
    }
  }, [value, currentGroupId, previousGroupId, previousGroupImage, imageName]);

  const uploadProps = useMemo(
    () => ({
      listType: "picture-card",
      accept: "image/*",
      fileList: Array.isArray(value) ? value : [],
      multiple: true,
      beforeUpload: (file) => {
        const isJpgOrPng =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/webp";
        if (!isJpgOrPng) {
          message.error("Bạn chỉ có thể upload ảnh JPG/PNG/WEBP!");
          return isJpgOrPng || Upload.LIST_IGNORE;
        }
        return false;
      },
      onChange: ({ fileList }) => {
        onChange(fileList);
      },
      customRequest: ({ onSuccess }) => {
        setTimeout(() => {
          onSuccess?.("ok");
        }, 0);
      },
    }),
    [onChange, value]
  );
  return useMemo(() => {
    return (
      <Upload {...uploadProps}>
        <span className="icon-plus" />
      </Upload>
    );
  });
};
export default AppMultiImage;

AppMultiImage.defaultProps = {
  maxCount: 1,
  value: [],
};
