export const isValidEmail = (value) => {
	return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value);
};

export const isEmpty = (obj) => {
	for (let key in obj) {
		// eslint-disable-next-line no-prototype-builtins
		if (obj.hasOwnProperty(key)) {
			return false;
		}
	}
	return true;
};

export const cleanObject = (obj) => {
	for (let propName in obj) {
		if (obj[propName] === null || obj[propName] === undefined) {
			delete obj[propName];
		}
	}
	return obj;
};

export const getFileName = (fullPath) => {
	if (fullPath) {
		let startIndex =
			fullPath.indexOf("\\") >= 0
				? fullPath.lastIndexOf("\\")
				: fullPath.lastIndexOf("/");
		let filename = fullPath.substring(startIndex);
		if (filename.indexOf("\\") === 0 || filename.indexOf("/") === 0) {
			filename = filename.substring(1);
		}
		return filename;
	}
	return null;
};
export const saveUserInLocalStorage = (user) => {
	localStorage.setItem("user", user ? JSON.stringify(user) : null);
};
