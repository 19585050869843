import { useCallback, useEffect, useRef, useState } from "react";
import { message } from "antd";
import PropTypes from "prop-types";
import AppColumnActionOption from "@cafefinz/core/AppColumnActionOption";
import AppConfirmationModal from "@cafefinz/core/AppConfirmationModal";
import useAppCreateOrEditModal from "hooks/UseAppCreateOrEditModal";
import { createOrEditRoleFieldConfig } from "./RolesConfig";
import useGetRoleForEdit from "hooks/apis/roles/useGetRoleForEdit";
import useDeleteRole from "hooks/apis/roles/useDeleteRole";
import useUpdateRole from "hooks/apis/roles/useUpdateRole";
import { UserPermission } from "shared/constants/AppEnums";
import IntlMessages from "@cafefinz/utility/IntlMessages";
import { useIntl } from "react-intl";
import useGetInfinityUsers from "hooks/apis/users/useGetInfinityUsers";

const RoleColumnActionOption = ({ roleId, updateListRoles }) => {
  const {messages} = useIntl();
  const { triggerGetRoleById, dataGetRoleById } = useGetRoleForEdit();
  const roleDataRef = useRef(null);
  useEffect(() => roleDataRef.current = dataGetRoleById, [dataGetRoleById])
  const { trigger: triggerEditRole } = useUpdateRole();
  const { trigger: triggerDeleteRole } = useDeleteRole();
  const convertFinishFormData = useCallback((formData) => {
    const { permissionRoles, users, ...restData } = formData;
    const { permissionRoles: previousPermissionRoles } = roleDataRef.current;
    const newPermissionRoles = permissionRoles.filter(value => !previousPermissionRoles.includes(value))
    const deletePermissionIds = previousPermissionRoles.filter(value => !permissionRoles.includes(value)).reduce((prev, curr) => `${prev},${curr}`, "")
    .replace(/^,+/, "");    
    const userIds = users?.map(item => typeof item === "object" ? item.value : item)

    return {
      ...restData,
      permissionRoles: newPermissionRoles,
      deletePermissionIds,
      userIds,
    }
  }, [])
  const [paramSelect, setParamSelect] = useState({
    user: "",
  });
  const { listUsers, fetchMoreUsers, isReachingEndUsers, isLoadingUsers } =
    useGetInfinityUsers(paramSelect);
  const onHandleEditRole = async (formData) => {
    triggerEditRole({
      formData: convertFinishFormData(formData),
      id: roleId,
    }, {
      onSuccess: () => {
        setTimeout(() => updateListRoles(), 0)
        updateListRoles()
        closeCreateOrEditModal();
				message.success(messages["app.roles.editRole.success"])
			},
      onError: () => {
				message.error(messages["app.roles.editRole.error"])
			}
    })
  }
  const {
		contextHolder: createOrEditContextHolder,
		openModal: openCreateOrEditModal,
		closeModal: closeCreateOrEditModal,
	} = useAppCreateOrEditModal({
    data: dataGetRoleById,
    fieldsConfig: createOrEditRoleFieldConfig({
      listUsers,
      fetchMoreUsers,
      isReachingEndUsers,
      loadingUsers: isLoadingUsers,
      searchOptionUsers: (value) =>
        setParamSelect((prev) => ({ ...prev, user: value })),
    }),
    title: <IntlMessages id="app.roles.editRole" />,
    onFinish: onHandleEditRole,
  });
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  
  const handleDeleteRole = async () => {
    triggerDeleteRole([roleId], {
      onSuccess: () => {
        message.success(messages["app.roles.deleteRole.success"])
        updateListRoles();
      },
      onError: () => message.success(messages["app.roles.deleteRole.error"]),
    })
    setIsDeleteConfirmationOpen(false)
  }
  return (
    <>
      <AppColumnActionOption
        items={[
          {
						scopes: [UserPermission.ROLES_EDIT],
						label: <IntlMessages id="app.roles.editRole" />,
						onClick: () => {
              triggerGetRoleById(roleId, {
                onSuccess: openCreateOrEditModal,
                onError: () => {
                  message.error("Something went wrong")
                }
              })
            }
					},
          {
						scopes: [UserPermission.ROLES_DELETE],
						label: <IntlMessages id="app.roles.deleteRole" />,
						onClick: () => setIsDeleteConfirmationOpen(true),
					},
        ]}
      />
      <AppConfirmationModal
				open={isDeleteConfirmationOpen}
				onConfirm={handleDeleteRole}
				onDeny={() => setIsDeleteConfirmationOpen(false)}
				modalTitle={<IntlMessages id="app.roles.deleteRole" />}
				paragraph={<IntlMessages id="app.roles.deleteRole.text" />}
			/>
      {createOrEditContextHolder}
    </>
  )
}

RoleColumnActionOption.propTypes = {
  roleId: PropTypes.number,
  updateListRoles: PropTypes.func,
}

export default RoleColumnActionOption;
