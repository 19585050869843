export const authRole = {
  admin: ["user", "admin"],
  user: ["user"],
};

export const RoutePermittedRole = {
  admin: "admin",
  user: "user",
};

export const ThemeStyle = {
  MODERN: "modern",
  STANDARD: "standard",
};
export const ThemeStyleRadius = {
  MODERN: 30,
  STANDARD: 4,
};
export const ThemeMode = {
  LIGHT: "light",
  SEMI_DARK: "semi-dark",
  DARK: "dark",
};
export const LayoutType = {
  FULL_WIDTH: "full-width",
  BOXED: "boxed",
  FRAMED: "framed",
};

export const MenuStyle = {
  DEFAULT: "default",
  STANDARD: "standard",
  ROUNDED: "rounded",
  ROUNDED_REVERSE: "rounded-reverse",
  CURVED_MENU: "curved-menu",
};

export const NavStyle = {
  DEFAULT: "default",
  MINI: "mini",
  MINI_SIDEBAR_TOGGLE: "mini-sidebar-toggle",
  STANDARD: "standard",
  HEADER_USER: "user-header",
  HEADER_USER_MINI: "user-mini-header",
  DRAWER: "drawer",
  BIT_BUCKET: "bit-bucket",
  H_DEFAULT: "h-default",
  HOR_HEADER_FIXED: "hor-header-fixed",
  HOR_DARK_LAYOUT: "hor-dark-layout",
};
export const FooterType = {
  FIXED: "fixed",
  FLUID: "fluid",
};
export const ThemeDirection = {
  RTL: "rtl",
  LTR: "ltr",
};
export const HeaderType = {
  DARK: "dark",
  LIGHT: "light",
};
export const RouteTransition = {
  NONE: "none",
  FADE: "fade",
  SLIDE_LEFT: "slideLeft",
  SLIDE_RIGHT: "slideRight",
  SLIDE_UP: "slideUp",
  SLIDE_DOWN: "slideDown",
};
export const Fonts = {
  LIGHT: "300",
  REGULAR: "400",
  MEDIUM: "500",
  BOLD: "600",
  EXTRA_BOLD: "600",
};

export const AuthType = {
  FIREBASE: "firebase",
  AWS_COGNITO: "aws_cognito",
  AUTH0: "auth0",
  JWT_AUTH: "jwt_auth",
};

export const AppAnimates = {
  SLIDEUPIN: {
    hidden: {
      y: "100vh",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.2,
      },
    },
  },
  SLIDEUPOUT: {
    hidden: {
      y: 0,
    },
    visible: {
      opacity: 0,
      y: "100vh",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDEDOWNIN: {
    hidden: {
      opacity: 0,
      y: "-100vh",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDEDOWNOUT: {
    hidden: {
      y: 0,
    },
    visible: {
      opacity: 0,
      y: "-100vh",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDELEFTIN: {
    hidden: {
      x: "100vw",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDELEFTOUT: {
    hidden: {
      x: 0,
    },
    visible: {
      opacity: 0,
      x: "100vw",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDERIGHTIN: {
    hidden: {
      x: "-100vw",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDERIGHTOUT: {
    hidden: {
      x: 0,
    },
    visible: {
      opacity: 0,
      x: "-100vw",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  FADEIN: {
    hidden: {
      opacity: 0,
      scale: 1,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        ease: "easeIn",
        when: "beforeChildren",
        staggerChildren: 0.15,
      },
    },
  },
  FADEOUT: {
    hidden: {
      opacity: 1,
      scale: 1,
    },
    visible: {
      opacity: 0,
      scale: 1,
      transition: {
        ease: "easeOut",
        when: "beforeChildren",
        staggerChildren: 0.15,
      },
    },
  },
};

export const AppAnimateGroups = {
  SLIDEUPIN: {
    hidden: {
      y: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.2,
        staggerChildren: 0.05,
      },
    },
  },
  SLIDEUPOUT: {
    hidden: {
      y: 0,
    },
    visible: {
      opacity: 0,
      y: "100vh",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
        staggerChildren: 0.15,
      },
    },
  },
  SLIDEDOWNIN: {
    hidden: {
      opacity: 0,
      y: "-100vh",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
        staggerChildren: 0.15,
      },
    },
  },
  SLIDEDOWNOUT: {
    hidden: {
      y: 0,
    },
    visible: {
      opacity: 0,
      y: "-100vh",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
        staggerChildren: 0.15,
      },
    },
  },
  SLIDELEFTIN: {
    hidden: {
      x: "100vw",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
        staggerChildren: 0.15,
      },
    },
  },
  SLIDELEFTOUT: {
    hidden: {
      x: 0,
    },
    visible: {
      opacity: 0,
      x: "100vw",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
        staggerChildren: 0.15,
      },
    },
  },
  SLIDERIGHTIN: {
    hidden: {
      x: "-100vw",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
        staggerChildren: 0.15,
      },
    },
  },
  SLIDERIGHTOUT: {
    hidden: {
      x: 0,
    },
    visible: {
      opacity: 0,
      x: "-100vw",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
        staggerChildren: 0.15,
      },
    },
  },
  FADEIN: {
    hidden: {
      opacity: 0,
      scale: 1,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        ease: "easeIn",
        when: "beforeChildren",
        staggerChildren: 0.15,
      },
    },
  },
  FADEOUT: {
    hidden: {
      opacity: 1,
      scale: 1,
    },
    visible: {
      opacity: 0,
      scale: 1,
      transition: {
        ease: "easeOut",
        when: "beforeChildren",
        staggerChildren: 0.15,
      },
    },
  },
  NOANIMATION: {
    hidden: {
      opacity: 1,
    },
    visible: {
      opacity: 1,
    },
  },
};

export const AppMotionAnimate = {
  SLIDEUPIN: {
    variants: {
      hidden: {
        y: 100,
        opacity: 0,
      },
      visible: {
        opacity: 1,
        y: 0,
      },
    },
    transition: {
      ease: "easeIn",
      when: "beforeChildren",
    },
  },
  SLIDEUPOUT: {
    variants: {
      hidden: {
        y: 0,
        opacity: 1,
      },
      visible: {
        opacity: 0,
        y: "-100vh",
      },
    },
    transition: {
      ease: "easeOut",
      when: "beforeChildren",
    },
  },
  SLIDEDOWNIN: {
    variants: {
      hidden: {
        y: -100,
        opacity: 0,
      },
      visible: {
        opacity: 1,
        y: 0,
      },
    },
    transition: {
      ease: "easeIn",
      when: "beforeChildren",
    },
  },
  SLIDEDOWNOUT: {
    variants: {
      hidden: {
        y: 0,
        opacity: 1,
      },
      visible: {
        opacity: 0,
        y: "100vh",
      },
    },
    transition: {
      ease: "easeOut",
      when: "beforeChildren",
    },
  },
  SLIDELEFTIN: {
    variants: {
      hidden: {
        x: 100,
        opacity: 0,
      },
      visible: {
        opacity: 1,
        x: 0,
      },
    },
    transition: {
      ease: "easeIn",
      when: "beforeChildren",
    },
  },
  SLIDELEFTOUT: {
    variants: {
      hidden: {
        x: 0,
        opacity: 1,
      },
      visible: {
        opacity: 0,
        x: "-100vw",
      },
    },
    transition: {
      ease: "easeOut",
      when: "beforeChildren",
    },
  },
  SLIDERIGHTIN: {
    variants: {
      hidden: {
        x: -100,
        opacity: 0,
      },
      visible: {
        opacity: 1,
        x: 0,
      },
    },
    transition: {
      ease: "easeIn",
      when: "beforeChildren",
    },
  },
  SLIDERIGHTOUT: {
    variants: {
      hidden: {
        x: 0,
        opacity: 1,
      },
      visible: {
        opacity: 0,
        x: "-100vw",
      },
    },
    transition: {
      ease: "easeOut",
      when: "beforeChildren",
    },
  },
  FADEIN: {
    variants: {
      hidden: {
        opacity: 0,
      },
      visible: {
        opacity: 1,
      },
    },
    transition: {
      duration: 0.1,
      ease: "easeIn",
      when: "beforeChildren",
    },
  },
  FADEOUT: {
    variants: {
      hidden: {
        opacity: 1,
      },
      visible: {
        opacity: 0,
      },
    },
    transition: {
      duration: 0.1,
      ease: "easeOut",
      when: "beforeChildren",
    },
  },
};

export const S3Type = {
  UID_AVATAR: "uid_avt",
  UID_WALL: "uid_wall",
  OWNER_AVATAR: "owner_avt",
  GID_AVATAR: "gid_avt",
  GID_WALL: "gid_wall",
  UID: "uid",
  GID: "gid",
  BID: "bid",
  WSID_FAVICON: "wsid_favicon",
  WSID_LOGO: "wsid_logo",
  UID_POST_TEMPLATE: "user_id_template",
  UID_CMT: "user_id_comment",
};

export const BannerMediaType = {
  IMAGE: "image",
  IMAGE_DETAIL: "imageDetail",
  VIDEO: "video",
};

export const BannerPositionType = {
  POST: "post",
  NOTIFICATION: "notification",
  EMAIL: "email",
};

export const AppInputType = {
  TEXT: "text",
  TEXT_AREA: "text_area",
  NUMBER: "number",
  PASSWORD: "password",
  SELECT: "select",
  UPLOAD_SINGLE_IMAGE: "uploadSingleImage",
  UPLOAD_MULTI_IMAGE: "uploadMultiImage",
  EDITOR: "editor",
  CHECKBOX: "checkbox",
  MULTISELECT: "multiselect",
  MULTISELECT_WITH_DATA: "multiselectWithData",
  SELECT_WITH_DATA: "selectWithData",
  DATE_PICKER: "datePicker",
  TIME_PICKER: "timePicker",
};

export const AppUserModalType = {
  LOCK: "Lock",
  UNLOCK: "Unlock",
  DELETE: "Delete",
};

export const UserSortKey = {
  ID: "id",
  FULL_NAME: "full_name",
  UNIQUE_URL: "unique_url",
  EMAIL: "email",
  GENDER: "gender",
  NATION: "n.name",
  REGIS_BY: "regis_by",
  TOTAL_THREADS: "total_threads",
  TOTAL_GROUP_JOINED: "totalGroupJoined",
  TOTAL_GROUP_CREATED: "totalGroupCreated",
  TOTAL_COMMENT: "totalComments",
  TOTAL_LIKES: "totalLikes",
  TOTAL_DIS_LIKES: "totalDisLikes",
};

export const GroupSortKey = {
  ID: "g.id",
  NAME: "g.name",
  UNIQUE_NAME: "g.uniqueName",
};

export const SortType = {
  ASC: "ASC",
  DESC: "DESC",
};

export const SettingType = {
  INFORMATION: "information",
  CAFEFINZ_RULE: "cafefinz_rules",
};

export const UserPermission = {
  USERS_CREATE: "Create User",
  USERS_DELETE: "Delete User",
  USERS_EDIT: "Edit User",
  EMAIL_ANALYSIS_DELETE: "Analysis Delete",
  EMAIL_ANALYSIS_VIEW: "Analysis View",
  EMAIL_CREATE: "Email Create",
  EMAIL_DELETE: "Email Delete",
  EMAIL_EDIT: "Email Edit",
  BANNER_CREATE: "Create Banner",
  BANNER_DELETE: "Delete Banner",
  BANNER_EDIT: "Edit Banner",
  GROUPS_CREATE: "Create Group",
  GROUPS_DELETE: "Delete Group",
  GROUPS_EDIT: "Edit Group",
  REPORT_ACTION: "Take Action",
  REPORT_DELETE: "Delete Report",
  SETTINGS_EDIT_INFORMATION: "Edit Information",
  SETTINGS_CREATE_RULE: "Create Rule",
  SETTINGS_DELETE_RULE: "Delete Rule",
  SETTINGS_EDIT_RULE: "Edit Rule",
  CATEGORY_CREATE: "Create Category",
  CATEGORY_DELETE: "Delete Category",
  CATEGORY_EDIT: "Edit Category",
  TAGS_CREATE: "Create Tag",
  TAGS_DELETE: "Delete Tag",
  TAGS_EDIT: "Edit Tag",
  ROLES_CREATE: "Create Role",
  ROLES_DELETE: "Delete Role",
  ROLES_EDIT: "Edit Role",
  POSTS_CREATE: "Create Post",
  POSTS_DELETE: "Delete Post",
  POSTS_EDIT: "Edit Post",
};

export const TypeCreateOrEdit = {
  CREATE: "CREATE",
  EDIT: "EDIT",
};
export const GenderEnum = {
  MALE: "Nam",
  FEMALE: "Nữ",
  OTHER: "Khác",
};

export const ThreadType = {
  ONLY_TEXT: 0,
  IMAGE: 1,
  VIDEO: 2,
  TEXT_LINK: 3,
  TEMPLATE: 4,
  SURVEY: 5,
  UPLOAD_VIDEO: 6,
};

export const RegexYoutubeLink =
  /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\\&v=|\?v=)([^#\\&\\?]*).*/;

export const FilterByTypeReport = {
  REPORT_TO_CAFEFINZ: "report-to-cafeFinz",
};

export const AppSearchColumn = {
  DATETIME: "DATE_TIME",
  INPUT_RANGE: "INPUT_RANGE",
  LIST_ITEM: "LIST_ITEM",
  INPUT_NUMBER: "INPUT_NUMBER",
};

export const DayOfWeekArr = ["mon", "tue", "web", "thu", "fri", "sat", "sun"];

export const DayOfWeek = {
  mon: "Thứ hai",
  tue: "Thứ ba",
  web: "Thứ tư",
  thu: "Thứ năm",
  fri: "Thứ sáu",
  sat: "Thứ bảy",
  sun: "Chủ nhật",
};

export const ThreadCriteriaType = {
  NEWEST_THREAD: "NEWEST_THREAD",
  FEATURED_THREAD: "FEATURED_THREAD",
  MOST_POPULAR_THREAD: "MOST_POPULAR_THREAD",
  FUNKY_THREAD: "FUNKY_THREAD",
};

export const ThreadCriteria = {
  NEWEST_THREAD: "Bài viết mới nhất",
  FEATURED_THREAD: "Bài viết được yêu thích nhất",
  MOST_POPULAR_THREAD: "Bài viết nổi bật",
  FUNKY_THREAD: "Bài viết sôi nổi",
};

export const TimeCriteriaType = {
  YESTERDAY: "YESTERDAY",
  CURRENT_7_DAYS: "CURRENT_7_DAYS",
  LAST_WEEK: "LAST_WEEK",
  CURRENT_30_DAYS: "CURRENT_30_DAYS",
  LAST_MONTH: "LAST_MONTH",
};

export const TimeCriteria = {
  YESTERDAY: "Ngày hôm qua",
  CURRENT_7_DAYS: "7 ngày gần nhất",
  LAST_WEEK: "Tuần vừa rồi",
  CURRENT_30_DAYS: "30 ngày gần nhất",
  LAST_MONTH: "Tháng vừa rồi",
};

export const FrequencyType = {
  EVERY_WEEK: "EVERY_WEEK",
  EVERY_DAY: "EVERY_DAY",
};

export const FrequencySendingMail = {
  EVERY_DAY: "Hằng ngày",
  EVERY_WEEK: "Hằng tuần",
};

export const BannerType = {
  VIDEO: "Video",
  IMAGE: "Ảnh",
};

export const bannerFilter = {
  BANNER_LOCATION: "Vị trí",
  BANNER_POST: "Bài viết",
  BANNER_NOTIFICATION: "Thông báo",
  BANNER_EMAIL: "Email",
  BANNER_TRUE: "Kích hoạt",
  BANNER_FALSE: "Không kích hoạt",
  BANNER_STATUS: "Trạng thái",
  BANNER_ALL: "- Toàn bộ -",
};

export const bannerFilterType = {
  POST: "post",
  NOTIFICATION: "notification",
  TRUE: "TRUE",
  FALSE: "FALSE",
};

export const StatusReportItem = {
  NoProcess: 1,
  NoDelete: 2,
  Deleted: 3,
};

export const RoleMember = {
  ADMIN: "Admin",
  MEMBER: "Member",
  MODERATOR: "Moderator",
};
