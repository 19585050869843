import { Col, Form, Tooltip } from "antd";
import moment from "moment";
import { AppInputType } from "shared/constants/AppEnums";
import PermissionTreeFormItem from "./PermissionTreeFormItem";
import RoleColumnActionOption from "./RoleColumnActionOption";
import "./index.style.less";
import IntlMessages from "@cafefinz/utility/IntlMessages";
import useIntl from "react-intl/lib/src/components/useIntl";
import AppLayzyImage from "@cafefinz/core/AppLayzyImage";

export const getRoleColumns = ({
  updateListRoles,
  handleShowsUserBelongRole,
  page,
}) => {
  const { messages } = useIntl();
  return [
    {
      key: "ordinal",
      title: <IntlMessages id="app.roles.ordinal" />,
      dataIndex: "ordinal",
      render: (_, record, index) => (page - 1) * 10 + index + 1,
      align: "center",
      width: 100,
    },
    {
      title: <IntlMessages id="app.roles.roleName" />,
      dataIndex: "name",
      key: "name",
      align: "center",
      width: 240,
      render: (_, record) => {
        const { name, isDefault } = record;
        return (
          <span>
            {name} {isDefault && <span className="role-default">Default</span>}
          </span>
        );
      },
    },
    {
      title: <IntlMessages id="app.roles.numOfUser" />,
      dataIndex: "numberOfUsers",
      key: "numberOfUsers",
      onCell: (value) => ({
        onClick: () => handleShowsUserBelongRole(value),
      }),
      align: "center",
      width: 240,
      render: (item) => ({
        props: {
          className: "cell-item",
        },
        children: (
          <Tooltip title={messages["app.roles.numOfUser"]}>{item}</Tooltip>
        ),
      }),
    },
    {
      title: <IntlMessages id="app.roles.createdBy" />,
      dataIndex: "createdUniqueUrl",
      key: "createdUniqueUrl",
      align: "center",
      width: 240,
    },
    {
      title: <IntlMessages id="app.roles.creationTime" />,
      dataIndex: "creationTime",
      key: "creationTime",
      render: (data) => {
        return moment(data).format("DD/MM/YYYY");
      },
      align: "center",
      width: 240,
    },
    {
      title: <IntlMessages id="app.actions" />,
      render: (_, record) => (
        <RoleColumnActionOption
          roleId={record?.id}
          updateListRoles={updateListRoles}
        />
      ),
      align: "center",
      width: 100,
    },
  ];
};

export const createOrEditRoleFieldConfig = ({
  listUsers,
  fetchMoreUsers,
  isReachingEndUsers,
  searchOptionUsers,
  loadingUsers,
}) => {
  const { messages } = useIntl();
  return [
    {
      name: "name",
      label: <IntlMessages id="app.roles.roleName" />,
      type: AppInputType.TEXT,
      maxLength: 50,
      rules: [
        {
          required: true,
          message: "Vui lòng nhập name",
        },
      ],
    },
    {
      name: "isDefault",
      label: <IntlMessages id="app.roles.isDefault" />,
      type: AppInputType.CHECKBOX,
    },
    {
      name: "isAccessCMS",
      label: <IntlMessages id="app.roles.accessCMS" />,
      type: AppInputType.CHECKBOX,
    },
    {
      name: "users",
      label: <IntlMessages id="app.roles.user" />,
      type: AppInputType.MULTISELECT,
      options: listUsers?.map(({ id, uniqueUrl }) => ({
        name: uniqueUrl,
        value: id,
      })),
      placeholder: messages["app.roles.user"],
      fetchMoreData: fetchMoreUsers,
      isReachingEnd: isReachingEndUsers,
      searchOption: searchOptionUsers,
      loading: loadingUsers,
    },
    {
      render: (data) => {
        return data?.users ? (
          <>
            <span style={{ marginLeft: "8px" }}> Số thành viên: </span>
            <span style={{ marginLeft: "105px" }}>{data.users.length}</span>
          </>
        ) : null;
      },
    },
    {
      render: () => {
        return (
          <Col sm={24}>
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.isAccessCMS !== curValues.isAccessCMS
              }
            >
              {({ getFieldValue }) => {
                const isDisabled = !getFieldValue("isAccessCMS");
                return (
                  <Form.Item
                    name="permissionRoles"
                    label={<IntlMessages id="app.roles.permission" />}
                    labelCol={{ span: 24 }}
                    labelAlign="left"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.accessCMS !== curValues.accessCMS
                    }
                  >
                    <PermissionTreeFormItem isDisabled={isDisabled} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        );
      },
    },
  ];
};

export const userBelongRoleColumns = () => {
  return [
    {
      title: <IntlMessages id="app.users.name" />,
      dataIndex: "fullName",
      key: "fullName",
      width: 50,
      sorter: true,
    },
    {
      title: <IntlMessages id="app.users.uniqueName" />,
      dataIndex: "uniqueUrl",
      key: "uniqueUrl",
      sorter: true,
    },
    {
      title: <IntlMessages id="app.users.avatar" />,
      dataIndex: "s3ProfilePictureFileName",
      key: "s3ProfilePictureFileName",
      render: (_, record) => (
        <AppLayzyImage
          src={record?.avatarUser}
          className="custom-avatar"
          alt="owner avatar"
        />
      ),
    },
    {
      title: <IntlMessages id="app.users.wallpaper" />,
      dataIndex: "wallImg",
      key: "wallImg",
      render: (_, record) => (
        <AppLayzyImage
          src={record?.wallpaperUser}
          className="custom-wallpaper"
          alt="owner avatar"
        />
      ),
    },
  ];
};
