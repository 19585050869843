import React from "react";

const Signin = React.lazy(() => import("./Signin"));
const ForgotPassword = React.lazy(() => import("./ForgotPassword"));
const ConfirmSignupAwsCognito = React.lazy(() =>
	import("./ConfirmSignupAwsCognito")
);
const ResetPasswordAwsCognito = React.lazy(() =>
	import("./ResetPasswordAwsCognito")
);

export const authRouteConfig = [
	{
		path: "/signin",
		element: <Signin />,
	},
	{
		path: "/forget-password",
		element: <ForgotPassword />,
	},
	{
		path: "/confirm-signup",
		element: <ConfirmSignupAwsCognito />,
	},
	{
		path: "/reset-password",
		element: <ResetPasswordAwsCognito />,
	},
];
