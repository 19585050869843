import { useCallback } from "react";
import useSWRMutation from "swr/mutation"
import { URL_API } from "@cafefinz/services/endpoint";
import { deleteRole } from "@cafefinz/services/apis/roles";

const useDeleteRole = () => {
  const deleteData = useCallback((_url, { arg }) => deleteRole(arg), [deleteRole])

  return useSWRMutation(URL_API.DELETE_ROLE, deleteData);
};

export default useDeleteRole;