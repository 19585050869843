import { GET_LIST_USERS } from "../../shared/constants/ActionTypes";

const initialState = {
	usersData: null,
	isLoading: false,
	isError: false,
};

const dashboardReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_LIST_USERS:
			return {
				...state,
				usersData: action.payload,
			};
		default:
			return state;
	}
};
export default dashboardReducer;
